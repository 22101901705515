import {combineReducers, createStore, applyMiddleware, compose} from 'redux'
//import logger from 'redux-logger'
import thunk from 'redux-thunk';
import HeaderReducer from '../components/HeaderMenu/HeaderReducer';
import FAQReducer from '../pages/FAQ/FAQReducer';
import RegisterReducer from '../pages/Register/RegisterReducer';
import EditReducer from '../pages/Edit/EditReducer';
import PreviewReducer from '../pages/Preview/PreviewReducer';
import userReduer from './userReducer';

const reducers = combineReducers({

    editPageObject: EditReducer,
    previewPageObject: PreviewReducer,
    userItem: userReduer,
    headerObject: HeaderReducer,
    faqList: FAQReducer,
    registerList: RegisterReducer,
})

export const store = createStore(reducers, compose(applyMiddleware(/*logger,*/ thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));