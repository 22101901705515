import * as userActionsTypes from './userActionsTypes';
import cookie from 'js-cookie'
import {cookieDomain} from './routes';

const initialState = {
    authenticationProccessing: false,
    proccessing: false,
    error: false,
    modalId: null,
    licniPodaci: {
        ime: '',
        prezime: '',
        imeRoditelja: '',
        datumRodjenja: '',
        profilnaSlika: null,
        cv: null,

    },
    kontakt: {
        telefon: '',
        linkedIn: '',
    },
    prebivaliste: {
        drzava: '',
        grad: '',
        adresa: '',
    },
    boraviste: {
        drzava: '',
        grad: '',
        adresa: '',
    },
    /*     
    srednjeObrazovanje: {
        tip: '',
        naziv: '',
        smer: '',
        grad: '',
        drzava: '',
    },
    */
    srednjeObrazovanje: [],
    visokoObrazovanje: [],
    iskustvo: {
        radnoIskustvo: [],
        strucnoUsavrsavanje: [],
        radNaRacunaru: [],
        radNaProjektu: [],
        poznavanjeJezika: [],
        ostaleVestine: {
            vozackeDozvole: '',
            vestine: '',
            osobine: '',
            interesovanja: ''
        }
    },
    experienceModalSelected: null,

    modalForDeletion: null,

    errorMessage: null,
    verifyMessage: '',
    modalMessage: '',
    cvForDisplay: '',

    showRegModal: false,
}

const addToArray = (array, newItem) => {

    let newArray = [...array.map(el => {
        if (el.id === newItem.id) {
            return newItem;
        }
        else {
            return el
        }
    })]

    if (array.find(el => el.id === newItem.id) === undefined) {
        newArray = [...newArray, newItem];
    }

    return newArray
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActionsTypes.FETCH_USER: {
            return {
                ...state,
                proccessing: true
            }
        }

        case userActionsTypes.LOGIN_FAIL: {

            const { message } = action;

            return {
                ...state,
                proccessing: false,
                error: true,
                errorMessage: message
            }
        }

        case userActionsTypes.LOGIN_SUCCESSFUL: {
            const { user } = action;

            cookie.set('id', user.userID, { expires: 2, domain: cookieDomain });
            cookie.set('token', user.token, { expires: 2, domain: cookieDomain });
            return {
                ...state,
                proccessing: false,
                error: false,

            }
        }

        case userActionsTypes.LOGOUT: {
            // sessionStorage.clear();
            cookie.remove('id', { expires: 2, domain: cookieDomain });
            cookie.remove('token', { expires: 2, domain: cookieDomain })

            return initialState
        }

        case userActionsTypes.REGISTER_USER: {

            return {
                ...state,
                proccessing: true,
                errorMessage: null,
            }
        }

        case userActionsTypes.REGISTER_USER_FAIL: {
            const { errorMessage } = action;

            return {
                ...state,
                proccessing: false,
                errorMessage
            }
        }

        case userActionsTypes.REGISTER_USER_SUCCESS: {

            return {
                ...state,
                proccessing: false,
                showRegModal: true,
                modalMessage: "Hvala vam na registraciji, da biste se ulogovali morate prvo aktivirati svoj nalog preko unetog email-a"
            }
        }

        case userActionsTypes.INFO_UPDATE_REQUEST: {

            return {
                ...state,
                proccessing: true
            }
        }

        case userActionsTypes.INFO_UPDATE_SUCCESS: {
            const { data } = action;


            const pom = { ...state };
            pom[`${data.field}`] = data.payload
            return {
                ...pom,
                proccessing: false,

            }

        }

        case userActionsTypes.FILE_UPLOADED: {
            const { field, link } = action;

            let pom = { ...state };
            pom.licniPodaci[`${field}`] = link;
            return {
                ...pom
            }
        }

        case userActionsTypes.IS_USER_LOGGED_IN: {

            return {
                ...state,
                authenticationProccessing: true

            }
        }

        case userActionsTypes.USER_LOGGED_IN_RESULT: {

            const { user } = action;

            if (user !== undefined) {

                let cv = user.licniPodaci.cv;
                let cvForDisplay;
                if (cv !== null) {
                    // Skup karaktera u splitu `_-` je nase interno kako na serveru razlikujemo cv-eve razlicitih korisnika
                    cvForDisplay = cv.split(`_-`).reverse()[0];

                }

                return {
                    ...state,
                    ...user,
                    authenticationProccessing: false,
                    cvForDisplay
                }
            }

            return {
                ...state,
                authenticationProccessing: false,
            }

        }
        case userActionsTypes.OPEN_MODAL: {
            const { id } = action;

            return {
                ...state,
                modalId: id
            }
        }

        case userActionsTypes.OPEN_EXPERIENCE_MODAL: {
            const { modal } = action;

            return {
                ...state,
                experienceModalSelected: modal
            }
        }

        case userActionsTypes.SUBMIT_FROM_MODAL: {

            return {
                ...state,
                modalId: null
            }
        }

        case userActionsTypes.SUBMIT_FROM_MODAL_CALLBACK: {
            const { response } = action;

            if (response.field !== undefined) {
                switch (response.field) {
                    case 'srednjeObrazovanje': {

                        return {
                            ...state,
                            srednjeObrazovanje: addToArray([...state.srednjeObrazovanje], response.payload)
                        }
                    }

                    case 'visokoObrazovanje': {

                        return {
                            ...state,
                            visokoObrazovanje: addToArray([...state.visokoObrazovanje], response.payload)
                        }
                    }

                    default: {

                        let newIskustvo = state.iskustvo;
                        newIskustvo[`${response.field}`] = addToArray([...newIskustvo[`${response.field}`]], response.payload)
                        return {
                            ...state,
                            iskustvo: { ...newIskustvo }
                        }
                    }
                }
            }
            else {
                return state
            }
        }

        case userActionsTypes.CHANGE_PROFILE_PICTURE: {
            const { picture } = action;

            return {
                ...state,
                licniPodaci: { ...state.licniPodaci, profilnaSlika: URL.createObjectURL(picture) }
            }
        }

        case userActionsTypes.CHANGE_CV: {
            const { file } = action;
            //console.log("userActionsTypes.CHANGE_CV", file);
            return {
                ...state,
                licniPodaci: { ...state.licniPodaci, cv: URL.createObjectURL(file) },
                cvForDisplay: file.name
            }
        }

        case userActionsTypes.PREPARE_FOR_DELETION: {
            const { modal } = action;


            const forServer = {
                field: modal.field,
                payload: {
                    id: modal.id
                }
            }

            return {
                ...state,
                modalForDeletion: forServer
            }
        }

        case userActionsTypes.MODAL_DELETED: {
            const { modal } = action;

            switch (modal.field) {
                case 'srednjeObrazovanje': {
                    return {
                        ...state,
                        srednjeObrazovanje: [...state.srednjeObrazovanje.filter(el => el.id !== modal.payload.id)]
                    }
                }

                case 'visokoObrazovanje': {
                    return {
                        ...state,
                        visokoObrazovanje: [...state.visokoObrazovanje.filter(el => el.id !== modal.payload.id)]
                    }
                }

                default: {
                    let newIskustvo = state.iskustvo;
                    newIskustvo[`${modal.field}`] = [...newIskustvo[`${modal.field}`].filter(el => el.id !== modal.payload.id)]
                    return {
                        ...state,
                        iskustvo: { ...newIskustvo }
                    }
                }
            }

        }

        case userActionsTypes.VERIFY_ACCOUNT_RESULT: {
            const { message } = action;



            return {
                ...state,
                verifyMessage: message
            }
        }

        case userActionsTypes.SET_MODAL_MESSAGE: {
            const { message } = action;

            return {
                ...state,
                modalMessage: message,
                showRegModal: true,
            }
        }

        case userActionsTypes.FORGOT_PASSWORD: {
            return {
                ...state,
                errorMessage: null,
                proccessing: true,
            }
        }

        case userActionsTypes.FORGOT_PASSWORD_CONFIRM: {

            return {
                ...state,
                modalMessage: "Na unetoj email adresi ćete dobili link za resetovanje šifre, ukoliko ne dobijete mail proverite spam folder i folder neželjene pošte. Ukoliko nakon 10 minuta uopšte niste dobili mail pošaljite zahtev ponovo",
                showRegModal: true,
                errorMessage: null,
                proccessing: false,
            }
        }

        case userActionsTypes.FORGOT_PASSWORD_DENIED: {

            return {
                ...state,
                errorMessage: "Na unetoj email adresi ne postoji nalog",
                proccessing: false,
            }

        }

        case userActionsTypes.OLD_ACCOUNT: {
            const { message } = action;

            return {
                ...state,
                modalMessage: message,
                showRegModal: true,
                proccessing: false,
            }
        }

        default:
            return state
    }
}

export default {userReducer, initialState};