import userReducer from '../../store/userReducer';
const initialState = userReducer.initialState;

const PreviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PREVIEW_OBJECT': {
            return Object.assign({}, state, action.payload )
        }
        default: return state;
    }
}
export default PreviewReducer;