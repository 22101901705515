import moment from "moment";
import { 
    verifyIfRequired, 
    verifyIfString, 
    verifyIfText,
    verifyIfOver18years,
    verifyIfEmail,
    verifyIf8CharatersLong,
    verifyIfAtLeastOneDigit,
    } from "../../helpers/validator/VerificationFunctions"

export const mainInformations = {
    headline: 'Osnovni podaci',
    id: 'osnovni-podaci-',
    fields: [
        { id: '1', label: '*Ime:', placeholder: 'Petar', field: 'name', value: '', state: 'empty', validateFunctions: [verifyIfRequired, verifyIfText], errorMessage: '' },
        { id: '2', label: 'Ime roditelja:', placeholder: 'Dušan', field: 'parentName', value: '', state: 'empty', validateFunctions: [verifyIfText], errorMessage: ''   },
        { id: '3', label: '*Prezime:', placeholder: 'Petrović', field: 'lastName', value: '', state: 'empty', validateFunctions: [verifyIfRequired, verifyIfText], errorMessage: ''   },
        { id: '4', label: '*Datum rođenja:', field: 'birthDate', type: "date", value: moment().format('YYYY-MM-DD'), state: 'empty', validateFunctions: [verifyIfOver18years], errorMessage: ''   }
    ]
}
export const contactInformations = {
    headline: 'Kontakt',
    id: 'kontakt-',
    fields: [
        { id: '1', label: '*Broj telefona:', placeholder: '+38160123456', field: 'phoneNumber', value: '', state: 'empty', validateFunctions: [verifyIfRequired], errorMessage: ''   },
        { id: '2', label: 'LinkedIn nalog:', placeholder: 'https://www.linkedin.com/in/ime-prezime/', field: 'linkedin', value: '', state: 'empty', validateFunctions: [], errorMessage: ''   },
    ]
}
export const residence1Info = {
    headline: 'Prebivalište',
    id: 'prebivaliste-',
    fields: [
        { id: '1', label: '*Država:', placeholder: 'Srbija', field: 'residence1Country', value: '', state: 'empty', validateFunctions: [verifyIfRequired, verifyIfText], errorMessage: ''   },
        { id: '2', label: '*Grad:', placeholder: 'Niš', field: 'residence1City', value: '', state: 'empty', validateFunctions: [verifyIfRequired, verifyIfText], errorMessage: ''   },
        { id: '3', label: '*Adresa:', placeholder: 'Aleksandra medvedeva 14, 18104 Niš', field: 'residence1Address', value: '', state: 'empty', validateFunctions: [verifyIfRequired], errorMessage: ''   },
    ]
}
export const residence2Info = {
    headline: 'Boravište',
    id: 'boraviste-',
    fields: [
        { id: '1', label: 'Država:', placeholder: 'Srbija', field: 'residence2Country', value: '', state: 'empty', validateFunctions: [verifyIfString], errorMessage: ''   },
        { id: '2', label: 'Grad:', placeholder: 'Niš', field: 'residence2City', value: '', state: 'empty', validateFunctions: [verifyIfString], errorMessage: ''   },
        { id: '3', label: 'Adresa:', placeholder: 'Aleksandra medvedeva 14, 18104 Niš', field: 'residence2Address', value: '', state: 'empty', validateFunctions: [], errorMessage: ''   },
    ]
}
export const loginInfo = {
    headline: 'Login informacije',
    id: 'login-informacije-',
    fields: [
        { id: '1', label: '*Email:', placeholder: 'test@test.com', field: 'email', value: '', state: 'empty', validateFunctions: [verifyIfRequired, verifyIfEmail], errorMessage: ''   },
        { id: '2', label: '*Šifra:', placeholder: '********', field: 'password', type: 'password', value: '', state: 'empty', validateFunctions: [verifyIfRequired, verifyIf8CharatersLong], errorMessage: ''   },
        { id: '3', label: '*Potvrdite šifru:', placeholder: '********', field: 'confirmPassword', type: 'password', value: '', state: 'empty', validateFunctions: [verifyIfRequired, verifyIf8CharatersLong], errorMessage: ''   },
    ]
}