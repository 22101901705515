import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Modal,
  Paper,
  Divider,
  TextField,
  Link,
  Button,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';

const styles = {
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24
  },
  contentWraper: {
    overflow: 'auto',
    display: "flex",
    flexDirection: 'column',
    height: '285px',
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  labelAndFieldBox: {
    marginBottom: '1.5%'
  },
  actionButtonsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  },
  actionButtonsBoxSmall: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  actionButtonsBoxSmall2: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    marginTop: '10px'
  },
  buttonStyle: {
    marginLeft: '2%',
    width: '100%'
  },
  buttonStyle1: {
    height: '2rem',
    fontSize: '12px'
  }
};

const ProjectsModal = (props) => {

  const [datumPocetka, setDatumPocetka] = useState(props.project.datumPocetka);
  const [datumZavrsetka, setDatumZavrsetka] = useState(props.project.datumZavrsetka);
  const [naziv, setNaziv] = useState(props.project.naziv);
  const [opis, setOpis] = useState(props.project.opis);
  const [uloga, setUloga] = useState(props.project.uloga);
  const [id, setId] = useState(props.project.id);

  const [openModalNtf, setOpenModalNtf] = useState(false);
  const handleOpenNtf = () => setOpenModalNtf(true);
  const handleCloseNtf = () => {
    setOpenModalNtf(false);
    props.handleClose();
  }

  const handleSubmit = () => {
    let userID = props.userID.toString();
    let newData = {
      payload: {
        datumZavrsetka: datumZavrsetka,
        datumPocetka: datumPocetka,
        naziv: naziv,
        opis: opis,
        uloga: uloga,
        fieldID: id,
        id: id,
        userID: userID
      },
      field: 'radNaProjektu'
    }
    props.handleChange(newData);
    handleOpenNtf();
  }

  const handleCloseAction = () => {
    props.handleClose();
  }

  useEffect(() => {
    setDatumPocetka(props.project.datumPocetka);
    setId(props.project.id);
    setDatumZavrsetka(props.project.datumZavrsetka);
    setOpis(props.project.opis);
    setNaziv(props.project.naziv);
    setUloga(props.project.uloga);
  }, [
    props.project.datumPocetka,
    props.project.id,
    props.project.datumZavrsetka,
    props.project.opis,
    props.project.naziv,
    props.project.uloga,
  ])

  return (
    <Modal
      open={props.open}
      onClose={handleCloseAction}
      aria-labelledby="modal-personal-data-title"
      sx={{ zIndex: '1800' }}
    >
      <Paper variant="outlined" sx={styles.modalStyle}>
        <div className="flex justify-between max-h-[500px] px-4 py-2">

          <div className="flex px-4 py-2 text-blue-500 sm:text-xs">
            <p className="text-s sm:text-lg font-semibold">Izmenite podatke o projektima</p>
          </div>

          <div className="flex">
            <IconButton
              onClick={handleCloseAction}
            /* sx={styles.iconButtonStyle} */
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <Divider />

        <Box sx={styles.contentWraper}>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '0.5%', fontSize: '14px' }}> Naziv: </Typography>
            <TextField
              required
              id="outlined-required"
              fullWidth
              defaultValue={naziv}
              onChange={(e) => setNaziv(e.target.value)}
              size="small"
            />
          </Box>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '0.5%', fontSize: '14px' }}> Uloga: </Typography>
            <TextField
              required
              id="outlined-required"
              fullWidth
              defaultValue={uloga}
              onChange={(e) => setUloga(e.target.value)}
              size="small"
            />
          </Box>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '0.5%', fontSize: '14px' }}> Datum početka: </Typography>
            <TextField
              required
              id="outlined-required"
              fullWidth
              type="date"
              defaultValue={datumPocetka}
              onChange={(e) => setDatumPocetka(e.target.value)}
              size="small"
            />
          </Box>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '0.5%', fontSize: '14px' }}> Datum završetka: </Typography>
            <TextField
              required
              id="outlined-required"
              fullWidth
              type="date"
              defaultValue={datumZavrsetka}
              onChange={(e) => setDatumZavrsetka(e.target.value)}
              size="small"
            />
          </Box>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '0.5%', fontSize: '14px' }}> Opis: </Typography>
            <TextField
              required
              id="outlined-required"
              fullWidth
              multiline
              rows={4}
              defaultValue={opis}
              onChange={(e) => setOpis(e.target.value)}
              size="small"
            />
          </Box>
        </Box>

        <Divider />

        <Box sx={styles.actionButtonsBox}>
          <Box sx={styles.actionButtonsBoxSmall}>
            <IconButton
            /* onClick = {handleOpenAddModal} */
            /* sx={styles.iconButtonStyle} */
            >
              <DeleteIcon sx={{ transform: 'scale(1.2)' }} />
            </IconButton>

            <Button onClick={handleSubmit} variant="contained">Sačuvaj</Button>
          </Box>
        </Box>

        <SuccessfulEditModal
          open={openModalNtf}
          handleClose={handleCloseNtf}
        />

      </Paper>
    </Modal>
  );
}

export default ProjectsModal;