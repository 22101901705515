import React, { useEffect, useState } from "react";
import {
  Box,
  Tooltip,
  IconButton,
  Typography
} from "@mui/material";
import CVUploadCard from "./LicniPodaciComponents/CVUploadCard";
import PersonalDataCard from "./LicniPodaciComponents/PersonalDataCard";
import ResidenceCard from "./LicniPodaciComponents/ResidenceCard";
import HighschoolEducationCard from "./LicniPodaciComponents/HighschoolEducationCard";
import HigherEducationCard from "./LicniPodaciComponents/HigherEducationCard";
import WorkExperienceCard from "./LicniPodaciComponents/WorkExperienceCard";
import ProfessionalTrainingCard from "./LicniPodaciComponents/ProfessionalTrainingCard";
import ProjectsCard from "./LicniPodaciComponents/ProjectsCard";
import SkillsCard from "./LicniPodaciComponents/SkillsCard";
import LanguageCard from "./LicniPodaciComponents/LanguageCard";
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';

import PolitikaPrivatnostiModal from "./LicniPodaciModals/PolitikaPrivatnostiModal";
import KompanijeModal from "./LicniPodaciModals/KompanijeModal";

const styles = {
  userPageWrapperBox: {
    maxWidth: "1000px",
    /* marginLeft: "18%",
    marginRight: "18%",
    marginTop: "1%",
    marginBottom: "4%", */
  },
  typographyStyle: {
    display: "flex",
    alignItems: "center"
  },
  iconButtonStyle: {
    "&:hover": {
      color: "primary.main",
    },
  },
  favouritesButtonActive: {
    color: "gold",
  },
  favouritesButtonInactive: {},
  downloadCVButtonStyle: {
    '&:hover': {
      color: 'primary.main'
    }
  }
};

const LicniPodaci = (props) => {

  const [openModal1, setOpenModal1] = useState(false);
  const handleOpen1 = () => setOpenModal1(true);
  const handleClose1 = () => setOpenModal1(false);

  const [openModal2, setOpenModal2] = useState(false);
  const handleOpen2 = () => setOpenModal2(true);
  const handleClose2 = () => setOpenModal2(false);

  //useEffect(()=>{console.log("LicniPodaci", props);},[props])

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-row w-10/12 items-center justify-between mx-4 my-1 max-w-5xl">
          <Tooltip title="Politika privatnosti">
              <IconButton
                onClick = {handleOpen1}
                sx={styles.iconButtonStyle}
              >
              <InfoIcon />
              </IconButton>
          </Tooltip>

          <Box>
            <Tooltip title="Ko ima pristup mojim podacima?">
              <IconButton
                onClick = {handleOpen2}
                sx={styles.iconButtonStyle}
                >
              <BusinessIcon/>
              </IconButton>
            </Tooltip>
          </Box>
      </div>

      {/* <PolitikaPrivatnostiModal 
        open={openModal1}
        handleClose={handleClose1}
      />

      <KompanijeModal
        open={openModal2}
        handleClose={handleClose2}
      /> */}

      <PersonalDataCard
        handleChange={props.handlers.updatePersonalData}
        handleChangeProfilePicture={props.handlers.updateProfilePicture}
        userID={props.licniPodaci.userID}
        preview={props.preview}
        licniPodaci={props.licniPodaci.licniPodaci}
        kontakt={props.licniPodaci.kontakt}
        email={props.licniPodaci.email}
      />

      <CVUploadCard 
      handleChange={props.handlers.updateCV}
      licniPodaci={props.licniPodaci.licniPodaci}
      CV={props.licniPodaci.licniPodaci.cv}
      preview={props.preview}
      />

      <ResidenceCard
          handleChange={props.handlers.updateResidence}
          userID={props.licniPodaci.userID}
          preview={props.preview}
          boraviste={props.licniPodaci.boraviste}
          prebivaliste={props.licniPodaci.prebivaliste}
      />

            <HighschoolEducationCard
              handleChange={props.handlers.updateHighschool}
              handleAdd={props.handlers.addHighschool}
              userID={props.licniPodaci.userID}
              preview={props.preview}
              srednjeObrazovanje={props.licniPodaci.srednjeObrazovanje}
            />
            <HigherEducationCard
              handleChange={props.handlers.updateUniversity}
              handleAdd={props.handlers.addUniversity}
              userID={props.licniPodaci.userID}
              preview={props.preview}
              visokoObrazovanje={props.licniPodaci.visokoObrazovanje}
            />
            <WorkExperienceCard
              handleChange={props.handlers.updateExperience}
              handleAdd={props.handlers.addExperience}
              userID={props.licniPodaci.userID}
              preview={props.preview}
              radnoIskustvo={props.licniPodaci.iskustvo.radnoIskustvo}
            />
            <ProfessionalTrainingCard
              handleDelete={props.handlers.deleteExperience}
              handleChange={props.handlers.updateExperience}
              handleAdd={props.handlers.addExperience}
              userID={props.licniPodaci.userID}
              preview={props.preview}
              strucnoUsavrsavanje={props.licniPodaci.iskustvo.strucnoUsavrsavanje}
            />
            <ProjectsCard
              handleChange={props.handlers.updateExperience}
              handleAdd={props.handlers.addExperience}
              handleDelete={props.handlers.deleteExperience}
              userID={props.licniPodaci.userID}
              preview={props.preview}
              radNaProjektu={props.licniPodaci.iskustvo.radNaProjektu}
            />
            {/* <SkillsCard preview={props.preview} radNaRacunaru={props.licniPodaci.iskustvo.radNaRacunaru} /> */}
            <SkillsCard 
              handleChange={props.handlers.updateExperience}
              handleAdd={props.handlers.addExperience}
              handleDelete={props.handlers.deleteExperience}
              userID={props.licniPodaci.userID}
              preview={props.preview} 
              skills={props.licniPodaci.iskustvo.radNaRacunaru} 
              />
            <LanguageCard
              handleChange={props.handlers.updateExperience}
              handleAdd={props.handlers.addExperience}
              handleDelete={props.handlers.deleteExperience}
              userID={props.licniPodaci.userID}
              preview={props.preview}
              poznavanjeJezika={props.licniPodaci.iskustvo.poznavanjeJezika}
            />

      <PolitikaPrivatnostiModal 
        open={openModal1}
        handleClose={handleClose1}
      />
      <KompanijeModal
        open={openModal2}
        handleClose={handleClose2}
      />
 
    </div>

  );
};

export default LicniPodaci;