import * as React from 'react';
import {
  Box,
  Typography,
  Modal,
  Paper,
  Divider,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import { useState, useEffect } from 'react';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const styles = {
  modalStyle: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    boxShadow: 24
  },
  contentWraper: {
    overflow: 'auto',
    display: "flex",
    flexDirection: 'column',
    height: '285px',
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  labelAndFieldBox: {
    marginBottom: '1.5%'
  },
  labelAndFieldBoxEnd: {
    marginBottom: '0.5%'
  },
  actionButtonsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  },
  actionButtonsBoxSmall: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    flexDirection: 'row'
  },
  actionButtonsBoxSmall2: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    marginTop: '10px'
  },
  buttonStyle: {
    marginLeft: '2%',
    width: '100%'
  },
  buttonStyle1: {
    height: '2rem',
    fontSize: '12px'
  }
};

const ResidenceModal = (props) => {

  //useState problem

  const [prebAdresa, setPrebAdresa] = useState(props.prebivaliste.adresa);
  const [prebGrad, setPrebGrad] = useState(props.prebivaliste.grad);
  const [prebDrzava, setPrebDrzava] = useState(props.prebivaliste.drzava);
  const [borAdresa, setBorAdresa] = useState(props.boraviste.adresa);
  const [borGrad, setBorGrad] = useState(props.boraviste.grad);
  const [borDrzava, setBorDrzava] = useState(props.boraviste.drzava);

  const [openModalNtf, setOpenModalNtf] = useState(false);
  const handleOpenNtf = () => setOpenModalNtf(true);
  const handleCloseNtf = () => 
    {
      setOpenModalNtf(false);
      props.handleClose();
    }

  const handleSubmit = () => {
    //console.log(props.boraviste , "BORAVISTE")
    let userID = props.userID.toString();
    let newData = {
      prebivaliste: {
       payload: {
         adresa: prebAdresa,
         drzava: prebDrzava,
         grad: prebGrad,
         userID: userID
       },
       field: 'prebivaliste'
     },
     boraviste: {
       payload: {
        adresa: borAdresa,
        drzava: borDrzava,
        grad: borGrad,
        userID: userID
       },
       field: 'boraviste'
     }
     }
     props.handleChange(newData);
     handleOpenNtf();
  }

  const handleCloseAction = () => {
    props.handleClose();
  }

  useEffect(() => {
    setPrebAdresa(props.prebivaliste.adresa);
    setPrebGrad(props.prebivaliste.grad);
    setPrebDrzava(props.prebivaliste.drzava);
    setBorAdresa(props.boraviste.adresa);
    setBorGrad(props.boraviste.grad);
    setBorDrzava(props.boraviste.drzava);
  },[props.prebivaliste.adresa,props.prebivaliste.grad,props.prebivaliste.drzava,props.boraviste.adresa,props.boraviste.grad,props.boraviste.drzava])

    return ( 
        <Modal
          open={props.open}
          onClose={handleCloseAction}
          aria-labelledby="modal-personal-data-title"
          aria-describedby="modal-PP-description"
          sx={{zIndex: '1800'}}
        >
        <Paper variant="outlined" sx={styles.modalStyle}>

        <div className="flex justify-between max-h-[500px] px-4 py-2">

                <div className="flex px-4 py-2 text-blue-500 sm:text-xs">
                    <p className="text-s sm:text-lg font-semibold">Izmenite prebivalište i boravište</p>
                </div>

                <div className="flex">
                    <IconButton
                        onClick = { handleCloseAction }
                        /* sx={styles.iconButtonStyle} */
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>

            <Divider />

          <Box sx={styles.contentWraper}>
            
            <Typography sx={{marginBottom: '0.5%', color: '#1976D2', fontSize: '18px', fontWeight: 'bold'}} variant="h7" component="h3"> Prebivalište </Typography>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> *Adresa: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={prebAdresa}
                onChange={(e) => setPrebAdresa(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> *Grad: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={prebGrad}
                onChange={(e) => setPrebGrad(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> *Država: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={prebDrzava}
                onChange={(e) => setPrebDrzava(e.target.value)}
                size="small"
              />
            </Box>

            <Typography sx={{marginTop: '1.5%', color: '#1976D2', fontSize: '18px', fontWeight: 'bold'}} variant="h7" component="h3"> Boravište </Typography>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> *Adresa: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={borAdresa}
                onChange={(e) => setBorAdresa(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> *Grad: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={borGrad}
                onChange={(e) => setBorGrad(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBoxEnd}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> *Država: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={borDrzava}
                onChange={(e) => setBorDrzava(e.target.value)}
                size="small"
              />
            </Box>

          </Box>


          <Divider />

          <Box sx={styles.actionButtonsBox}>
            
                <Box sx={styles.actionButtonsBoxSmall}>
                    
                    {/* <IconButton>
                        <DeleteIcon sx={{ transform: 'scale(1.2)' }} />
                    </IconButton> */}
                    
                    <Button onClick={handleSubmit} variant="contained">Sačuvaj</Button>
                </Box>
                    
                </Box>

          <SuccessfulEditModal 
            open={openModalNtf}
            handleClose={handleCloseNtf}
          />
        </Paper>
      </Modal>
    );
}
 
export default ResidenceModal;