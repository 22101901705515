import * as userService from '../../store/userService';

export const registerUser = (user) => async dispatch => {
    //console.log(", registerUser:", user)
    userService.registerUser(user).then((data) => { 
        //console.log(data);
        dispatch({ type: 'UPDATE_EDIT_OBJECT', payload: data });
    });
}

export const changeStep = (data) => async dispatch => {
    dispatch({ type: 'UPDATE_STEP', payload: data });
}