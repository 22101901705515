import * as React from 'react';
import { Box, Typography, Modal, Paper, Divider, Button, Link } from '@mui/material';

import LogoBest from "../../../assets/BESTNis-tr.png";

//GENERLANI
import quest from "../../../assets/Pokrovitelji/G1 quest.png";
import diffine from "../../../assets/Pokrovitelji/G2 diffine.png";
//ZLATNI
import prime from "../../../assets/Pokrovitelji/Z1 prime.png";
import syrmia from "../../../assets/Pokrovitelji/Z2 syrmia.png";
import fazi from "../../../assets/Pokrovitelji/Z3 fazi.png";
import cubic from "../../../assets/Pokrovitelji/Z4 cubic.png";
import dualsoft from "../../../assets/Pokrovitelji/Z5 dualsoft.png";
import webelinx from "../../../assets/Pokrovitelji/Z6 webelinx.png";
import ingSoft from "../../../assets/Pokrovitelji/Z7 ing.png";
//SREBRNI
import atessoft from "../../../assets/Pokrovitelji/S1 atessoft.jpg";
import logik from "../../../assets/Pokrovitelji/S2 logik.png";
import imi from "../../../assets/Pokrovitelji/S3 imi.png";
import futurenet from "../../../assets/Pokrovitelji/S4 futurenet.png";
import codemancy from "../../../assets/Pokrovitelji/S5 codemancy.png";
import leoni from "../../../assets/Pokrovitelji/S6 leoni.jpg";
import hdl from "../../../assets/Pokrovitelji/S7 hdl.jpg";
import nistruct from "../../../assets/Pokrovitelji/S8 nistruct.png";
import droxic from "../../../assets/Pokrovitelji/S9 droxic.png";
import brose from "../../../assets/Pokrovitelji/S10 brose.png";
import johnson from "../../../assets/Pokrovitelji/S11 johnson.png";
import flowninja from "../../../assets/Pokrovitelji/S12 flowninja.png";
import accordia from "../../../assets/Pokrovitelji/S13 accordia.png";
//BRONZANI
import danieli from "../../../assets/Pokrovitelji/B1 danieli.png";
import naisus from "../../../assets/Pokrovitelji/B2 naisus.png";
import ego from "../../../assets/Pokrovitelji/B3 ego.png";
import veriest from "../../../assets/Pokrovitelji/B4 veriest.png";
import diligent from "../../../assets/Pokrovitelji/B5 diligent.png";
import vtool from "../../../assets/Pokrovitelji/B6 vtool.jpg";
import pascom from "../../../assets/Pokrovitelji/B7 pascom.png";
import bat from "../../../assets/Pokrovitelji/B8 bat.png";
import joorney from "../../../assets/Pokrovitelji/B9 joorney.png";
import senis from "../../../assets/Pokrovitelji/B10 senis.png";

const styles = {
  modalStyle: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    boxShadow: 24
  },
  /* contentWraper: {
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '285px',
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  labelAndFieldBox: {
    marginBottom: '1.5%'
  },
  actionButtonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  },
  actionButtonsBoxSmall: {
    display: 'flex',
    flexDirection: 'row'
  },
  buttonStyle: {
    marginLeft: '2%',
  },
  Typography1first: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '6px',
    textAlign: 'justify',
  },
  Typography1: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '12px',
    textAlign: 'justify',
  },
  Typography2: {
    textAlign: 'justify',
  },
  Typography2last: {
    marginBottom: '12px',
  } */
};

const KompanijeModal = (props) => {

  const handleCloseAction = () => {
    props.handleClose();
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-PP-title"
      aria-describedby="modal-PP-description"
      sx={{ zIndex: '1800' }}
    >

      <Paper variant="outlined" sx={styles.modalStyle}>
        <div className="flex flex-col justify-center max-h-[500px] px-4 py-2">

          <div className="flex justify-center items-center px-4 py-2 text-blue-500 sm:text-xs">
            <p className="text-s sm:text-lg font-semibold">Ko ima pristup mojim podacima?</p>
          </div>

          <Divider />

          <div className="flex flex-col justify-start items-center text-justify px-4 py-2 overflow-y-auto">

            <div className="flex flex-row justify-start items-center text-justify py-2">
              <a className="hidden md:flex pr-3" href="https://bestnis.rs/" target="_blank" rel="noreferrer">
                <img className="max-w-[130px] w-full mx-auto py-2" src={LogoBest} alt=""></img>
              </a>
              <p>
                Udruženje studenata tehnike Evrope - Niš (BEST Niš) kao organizator događaja
                zadržava pravo uvida u date podatke prateći svoju
                <a href="https://jobfairnis.rs/"
                  rel="noreferrer" target="_blank" className="font-medium text-blue-700 dark:text-blue-500 hover:underline cursor-pointer"> politiku privatnosti
                </a>. Takođe, pokrovitelji događaja potpisanim ugovorima dobijaju pravo uvida u podatke kojima Job Fair CV App raspolaže.
              </p>
            </div>

            {/* GENERALNI */}
            <div className="flex flex-col flex-wrap justify-between items-center text-left py-2">
              <p>
                Ovogodišnji <b>generalni pokrovitelji</b>:
              </p>
              <div className="flex justify-around space-x-10 items-center">
                <a href="https://jobfairnis.rs/7952-2/" target="_blank" rel="noreferrer">
                  <img className="max-w-[150px] w-full mx-auto py-2" src={quest} alt=""></img>
                </a>
                <a href="https://jobfairnis.rs/diffine-2/" target="_blank" rel="noreferrer">
                  <img className="max-w-[150px] w-full mx-auto py-2" src={diffine} alt=""></img>
                </a>
              </div>
            </div>

            {/* ZLATNI */}
            <div className="flex flex-col flex-wrap justify-between items-center text-left py-2">
              <p>
                Ovogodišnji <b>zlatni pokrovitelji</b>:
              </p>
              <div className="flex flex-col flex-wrap justify-between items-center">
                <div className="flex justify-center items-center flex-wrap flex-col sm:flex-row">
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/prime-software/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={prime} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/syrmia/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={syrmia} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/7928-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={fazi} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/cubic-motiondsp/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={cubic} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/dualsoft/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={dualsoft} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/webelinx/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={webelinx} alt=""></img>
                    </a>
                  </div>
                  <div className="flex justify-around items-center px-3">
                    <a href="https://jobfairnis.rs/ingsoftware/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-auto py-2" src={ingSoft} alt=""></img>
                    </a>
                  </div>
                </div>
              </div>

            </div>

            {/* SREBRNI */}
            <div className="flex flex-col flex-wrap justify-between items-center text-left py-2">
              <p>
                Ovogodišnji <b>srebrni pokrovitelji</b>:
              </p>
              <div className="flex flex-col flex-wrap justify-between items-center">
                <div className="flex justify-center items-center flex-wrap flex-col sm:flex-row">
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/ates-soft/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={atessoft} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/logik-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={logik} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/imi-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={imi} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/futurenet-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={futurenet} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/codemancy-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={codemancy} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/leoni/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={leoni} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/hdl-design-house/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={hdl} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/nistruct-3/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={nistruct} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/droxic-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={droxic} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/brose/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={brose} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/7934-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={johnson} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/flow-ninja/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={flowninja} alt=""></img>
                    </a>
                  </div>
                  <div className="flex justify-around items-center px-3">
                    <a href="https://jobfairnis.rs/accordia-group/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-auto py-2" src={accordia} alt=""></img>
                    </a>
                  </div>
                </div>
              </div>

            </div>

            {/* BRONZANI */}
            <div className="flex flex-col flex-wrap justify-between items-center text-left py-2">
              <p>
                Ovogodišnji <b>bronzani pokrovitelji</b>:
              </p>
              <div className="flex flex-col flex-wrap justify-between items-center">
                <div className="flex justify-center items-center flex-wrap flex-col sm:flex-row">
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/7956-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={danieli} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/naissus-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={naisus} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/super-ego-holding/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={ego} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/veriest/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={veriest} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/diligent-3/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={diligent} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/vtool/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={vtool} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/pascom-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={pascom} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/british-american-tobacco/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={bat} alt=""></img>
                    </a>
                  </div>
                  <div className="flex items-center mx-2">
                    <a href="https://jobfairnis.rs/joorney-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={joorney} alt=""></img>
                    </a>
                    <a href="https://jobfairnis.rs/senis-2/" target="_blank" rel="noreferrer">
                      <img className="sm:max-w-[130px] max-w-[80px] w-full mx-3 py-2" src={senis} alt=""></img>
                    </a>
                  </div>

                </div>
              </div>

            </div>

          </div>



          <Divider />

          <div className='flex justify-between items-center px-4 py-2'>

            <div className="flex flex-row justify-center text-gray-800">
              <a href="https://jobfairnis.rs/"
                rel="noreferrer" target="_blank" className="font-medium text-blue-700 dark:text-blue-500 hover:underline cursor-pointer">
                Saznaj više.
              </a>
            </div>

            <Box sx={styles.actionButtonsBoxSmall}>
              <Button onClick={handleCloseAction} sx={styles.buttonStyle} variant="outlined">Nazad</Button>
            </Box>

          </div>

        </div>
      </Paper>

    </Modal>
    /* <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-PP-title"
        aria-describedby="modal-PP-description"
      >
        <Paper variant="outlined" sx={styles.modalStyle}>

        <Typography sx={{p: 1.5, color: '#1976D2'}} id="modal-PP-title" variant="h6" component="h2">
          Ko ima pristup mojim podacima?
        </Typography>

        <Divider />

        <Box sx={styles.contentWraper}>

          <Typography id="modal-PP-description" sx={styles.Typography1first}>
          GENERALNI POKROVITELJI
          </Typography>
          <Typography id="modal-PP-description" sx={styles.Typography2}>
          Uskoro...
          </Typography>

          <Typography id="modal-PP-description" sx={styles.Typography1}>
          ZLATNI POKROVITELJI
          </Typography>
          <Typography id="modal-PP-description" sx={styles.Typography2}>
          Uskoro...
          </Typography>

          <Typography id="modal-PP-description" sx={styles.Typography1}>
          SREBRNI POKROVITELJI
          </Typography>
          <Typography id="modal-PP-description" sx={styles.Typography2}>
          Uskoro...
          </Typography>

          <Typography id="modal-PP-description" sx={styles.Typography1}>
          BRONZANI POKROVITELJI
          </Typography>
          <Typography id="modal-PP-description" sx={styles.Typography2last}>
          Uskoro...
          </Typography>

        </Box>

        <Divider />

        <Box sx={styles.actionButtonsBox}>
        
        <Typography variant="subtitle2">
          <Link href="https://jobfairnis.rs/" underline="hover">
            {'Saznaj više o pokroviteljima ovogodišnjeg JobFair-a ovde.'}
          </Link>
        </Typography>
        
        <Box sx={styles.actionButtonsBoxSmall}>
          <Button onClick={handleCloseAction} sx={styles.buttonStyle} variant="outlined">Nazad</Button>
        </Box>
        
      </Box>

        </Paper>
      </Modal> */
  );
}

export default KompanijeModal;