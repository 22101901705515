import {
  CircularProgress,
  IconButton,
  Box
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useCallback, useEffect } from "react";
import JF2023NoviLogo from "../../assets/JF2023.png";
import forgotPassword from "../../assets/forgotPassword.png";
import LogoBest from "../../assets/BESTNis-tr.png";
import history from "../../common/history";
import { sendEmailForgotPassword} from "./ForgotPasswordActions";
import { connect } from "react-redux";


const styles = {
  background: {
    backgroundColor: "F4EBEB",
    height: "100vh",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    backgroundSize: "contain",
  },

  bigContainer: {
    backgroundColor: "F4EBEB",
    align:"ceter",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingLeft: "175px",
    paddingTop: "35px",
    borderRadius: "10px",
  },
  
  mainContainer: {
    align:"right",
    width: "490px",
    display: "flex",
    paddingLeft: "200px",
    paddingTop: "25px",
    flexDirection: "column",
    borderRadius: "10px",
  },

  logoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems:"center",
    marginBottom: '60px'
  },
  
  error: {
    color: "red",
    textAlign: 'center',
    marginTop: '10px'
  },
  iconContainer: {
    margin: "6px",
    width: '100%',
  },
  spinner: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
};

const ForgotPassword = (props) => {

  const [title, setTitle] = useState("CV App");
  const [data, setData] = useState({ email: "" });
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTitle(`Oporavak lozinke | CV App`);
    /* props.updateEditObject(); */
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const sender = "";

  const handleKeyPress = useCallback(event => {
    if (event.keyCode === 13) {
      sendEmail();
    }
  }, [data])

  const sendEmail = () => {
    const recipient = encodeURIComponent(data.email);
    const subject = encodeURIComponent("Oporavak naloga");
    const body = encodeURIComponent("Link do reset pass");
    props.sendEmailForgotPassword(data);
    alert("Email za promenu šifre je poslat na Vašu adresu.");
    history.push('/login');
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full bg-FFFDFA">
        <div className="hidden sm:flex flex-col justify-center h-screen">
            <img className="max-w-[500px] w-full m-auto max-h-[500px]" src={forgotPassword} alt=""></img>
        </div>
        <div className="bg-FFFDFA flex flex-col justify-center">
          <form className="max-w-[550px] w-full mx-auto p-4">
            <div className="flex justify-end mb-5">
              <IconButton type="button" class="text-white bg-gradient-to-r from-blue-500 via-blue-600
                    to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300
                    dark:focus:ring-blue-800 font-medium text-m rounded-full p-2 text-center" onClick={() => history.push('/login')}>
                  <CloseIcon style={{ color: 'white' }} />
              </IconButton> 
            </div>
            <a href="https://jobfairnis.rs/" target="_blank" rel="noreferrer">
              <img className="max-w-[350px] w-full mx-auto" src={JF2023NoviLogo} alt=""></img>
            </a>
            <div class="my-6">
              <input type="email" id="email" class="bg-slate-50 border
               border-gray-300 text-gray-900 text-m rounded-[10px]
               focus:ring-blue-500 focus:border-blue-500 block w-full
                p-3.5 dark:EDEDED" placeholder="Email adresa"
                required
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
            </div>
            {!spinner ? (
              <button type="button" class="text-white bg-gradient-to-r from-blue-500 via-blue-600
              to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300 w-full
              dark:focus:ring-blue-800 font-medium rounded-lg text-m p-3 text-center mr-2 mb-1"
              onClick={() => sendEmail()}> Pošaljite email </button>
            ) : (
              <Box sx={styles.spinner}>
                <CircularProgress sx={{ color: "#1b75bc" }} />
              </Box>
            )}
            {!error ? (
              <div></div>
            ) : (
              <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 rounded cursor-default" role="alert">
                <p class="font-bold">UPOZORENJE!</p>
                <p>Pogrešan mejl, probajte ponovo.</p>
              </div>
            )}

            <a href="https://bestnis.rs/" target="_blank" rel="noreferrer">
              <img className="max-w-[130px] w-full mx-auto pt-2" src={LogoBest} alt=""></img>
            </a>
          </form>
        </div>
    </div>
    /* <Box sx={styles.background}>
      <Box sx={styles.bigContainer}>
        <Box sx={{ display: "flex", justifyContent: "left" }}>
          <img src={forgotPassword} width="500" height="500" alt="forgot password" />
        </Box>
        <Box sx={styles.mainContainer}>
          <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
            <img src={JF22OriginalniLogo} width="455" className="JFLogoImg" alt="logo" />
          </Box>
          <Box>
            <TextField
              sx={{ marginBottom: "10px" }}  
              placeholder={"E-mail adresa za oporavak naloga"}
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              fullWidth
            />
          </Box>
          {!spinner ? (
            <Button
              variant={"contained"}
              sx={{ borderRadius: "10px", backgroundColor: "#1b75bc", color: "white", marginBottom: "15px" }}
              onClick={() => sendEmail()}
            >
              Prosledite e-mail
            </Button>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
              <CircularProgress sx={{ color: "#1b75bc" }} />
            </Box>
          )}
          {error && (
            <Typography variant={"body1"} sx={{ color: "red", textAlign: "center", marginBottom: "10px" }}>
              {error}
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <img src={LogoBest} width="225" />
          </Box>
        </Box>
      </Box>
    </Box> */
  );
};

const mapStateToProps = (state) => {
  return {
  }; 
};

export default connect(mapStateToProps, 
  { sendEmailForgotPassword
  })(ForgotPassword);