import moment from "moment";

export const verifyIfEmpty = (value) => {
    if (!!value) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Polje je prazno'
        };
    }
}

export const verifyIfRequired = (value) => {
    if (!!value) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Ovo je obavezno polje'
        };
    }
}


export const verifyIfString = (value) => {
    if (!value) {
        return {
            'isValid': true,
            'message': ''
        };
    }

    var isText = new RegExp("[a-zA-ZšđčćžŠĐČĆŽ]");
    if (isText.test(value)) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Polje mora da sadrži samo velika i mala slova'
        };
    }
}

export const verifyIfText = (value) => {
    if (!value) {
        return {
            'isValid': true,
            'message': ''
        };
    }

    var isText = new RegExp("[a-zA-ZšđčćžŠĐČĆŽ]");
    if (isText.test(value)) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Polje mora da sadrži samo velika i mala slova'
        };
    }
}

export const verifyIfOver18years = (value) => {
    let eighteenYearsInMiliseconds = 568024668000;
    let nowInMiliseconds = moment().valueOf();
    //console.log("verifyIfOver18years")
    //console.log("nowInMiliseconds", nowInMiliseconds);
    //console.log(" moment(field.value).valueOf()", moment(field.value).valueOf());
    var ageInMiliseconds = nowInMiliseconds - moment(value).valueOf();

    if (ageInMiliseconds > eighteenYearsInMiliseconds) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Morate da imate iznad 18 godina'
        };
    }
}

export const verifyIfPhoneNumber = (value) => {
    if (!value) {
        return {
            'isValid': true,
            'message': ''
        };
    }

    var isText = new RegExp(`(\\+?\\d){1}`);
    if (isText.test(value)) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Nije validan broj telefona'
        };
    }
}

export const verifyIfEmail = (value) => {
    var isText = new RegExp(`^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`);
    if (isText.test(value)) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Nije validan Email'
        };
    }
}

export const verifyIf8CharatersLong = (value) => {
    if (value.length >= 8) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Minimalna dužina 8 karaktera'
        };
    }
}
export const verifyIfAtLeastOneDigit = (value) => {
    //NE RADI KAKO TREBA
    var isText = new RegExp(`/\d/g`);
    if (isText.test(value)) {
        return {
            'isValid': true,
            'message': ''
        };
    } else {
        return {
            'isValid': false,
            'message': 'Šifra mora sadržati barem jednu brojku'
        };
    }
}

