import React from "react";
import {
  Divider,
  Paper,
  Typography,
  Box,
  Tooltip,
  IconButton
  } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
/* import LetterAvatar from "lettered-avatar"; */
import HighschoolEducationModal from "../LicniPodaciModals/HighschoolEducationModal";
import { useState } from "react";

import Avatar from '@mui/material/Avatar';
import BackpackIcon from '@mui/icons-material/Backpack';
import AddIcon from '@mui/icons-material/Add';
import HighschoolEducationModalAdd from "../LicniPodaciModalsAdd/HighschoolEducationModalAdd";

  const styles = {
    paperStyle: {
      display: 'flex',
      flexDirection: 'column',
      //maxWidth: '1100px',
      maxWidth: '64rem',/* 1024px */
      width: '85%',
      flexWrap: 'wrap',
      padding: '24px',
      marginTop: '1.5%',
      borderRadius: '10px'
    },
    contentStyle0: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '1%',
    },
    headingStyle : {
      textAlign: 'start',
      marginBottom: '1%',
      color: 'primary.main',
      fontSize: '20px',
    },
    buttonsStyle : {
      display: 'flex',
      justifyContent: 'right',
      flexGrow: 1
    },
    contentStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '2%',
    },
    dataStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "left",
      flexGrow: 13
    },
    avatarWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '3%',
    },
    dateStyles: {
      textAlign: 'start',
      color: '#9592AE'
    },
    typographyStyle: {
      textAlign: 'start', 
      marginRight: '3px', 
      fontSize: 14,
      textAlign: 'left'
    }
  }

const HighschoolEducationCard = (props) => {

  const [openAddModal, setOpenAddModal] = useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const [openModal, setOpenModal] = useState(-1);
  const handleOpen = (modalID) => {
    setOpenModal(modalID)
  };
  const handleClose = () => setOpenModal(-1);

    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>

        <Box sx={styles.contentStyle0}>
          <Typography sx={styles.headingStyle}> Srednje obrazovanje </Typography>

          <Box sx={styles.buttonsStyle}>
              {!props.preview &&
                <Box>
                  <Tooltip title="Dodaj">
                  <IconButton
                    onClick = {handleOpenAddModal}
                    sx={styles.iconButtonStyle}
                  >
                  <AddIcon />
                  </IconButton>
                  </Tooltip>
                  <HighschoolEducationModalAdd
                    userID={props.userID}
                    handleAdd={props.handleAdd}
                    open={openAddModal}
                    handleClose={handleCloseAddModal}
                  />
                </Box>
              }
            </Box>
          </Box>

          <Divider />
          {props.srednjeObrazovanje.length > 0 && props.srednjeObrazovanje.map((item, index) => (

          <Box key={index} sx={styles.contentStyle}>

            <Box className="hidden sm:flex items-center mr-[3%]" /* sx={styles.avatarWrapper */ >
              <Avatar sx={{ bgcolor: '#1B75BC', width: 50, height: 50 }} variant="rounded">
                <BackpackIcon />
              </Avatar>    
            </Box>

            <Box className="sm:flex flex-col justify-start flex-13" /* sx={styles.dataStyle} */>
               
                  <Typography align="left" sx={{...styles.typographyStyle, fontSize: '13px'}}>
                    {item.tip}
                  </Typography>
                  <Typography align="left" sx={{...styles.typographyStyle, fontWeight: '600'}}>
                    {`${item.naziv}, ${item.smer}`}
                  </Typography>
                  <Typography align="left" sx={{...styles.typographyStyle,color: '#9592AE', fontSize: '13px'}} variant="subtitle2">
                    {`Mesto: ${item.grad}, ${item.drzava}`}
                  </Typography>
                  <Typography align="left" sx={{...styles.typographyStyle,color: '#9592AE', fontSize: '13px'}} variant="subtitle2">
                    {`Godina završetka: ${item.godinaZavrsetka}.`}
                  </Typography>
           
            </Box>

            <Box sx={styles.buttonsStyle}>
              {!props.preview &&
              <Box>
                <Tooltip title="Izmeni">
                <IconButton
                  onClick = {() => handleOpen(index)}
                  sx={styles.iconButtonStyle}
                >
                <EditIcon />
                </IconButton>
                </Tooltip>
              </Box>
              }
            </Box>

            <HighschoolEducationModal
              userID={props.userID}
              handleChange={props.handleChange}
              srednjeObrazovanje={item}
              open={openModal === index ? true : false}
              handleClose={handleClose}
            />

          </Box>
          
          ))}

        </Paper>
     );
}
 
export default HighschoolEducationCard;