import React, { useEffect, useState } from 'react';
import { 
  Box,
  Tooltip,
  Typography,
  IconButton
} from '@mui/material';
import { fetchFAQ } from './FAQActions';
import { connect } from 'react-redux';
import QACard from '../../components/QACard/QACard'
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';
import PolitikaPrivatnostiModal from "../../components/LicniPodaci/LicniPodaciModals/PolitikaPrivatnostiModal";
import KompanijeModal from "../../components/LicniPodaci/LicniPodaciModals/KompanijeModal";


const styles = {
  mainStyle: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap', 
    padding: '24px',
    width: '85%',
    justifyContent:'space-between',
    borderRadius: '10px'
  },
};

const FAQ = (props) => {

  const [openModal1, setOpenModal1] = useState(false);
  const handleOpen1 = () => setOpenModal1(true);
  const handleClose1 = () => setOpenModal1(false);

  const [openModal2, setOpenModal2] = useState(false);
  const handleOpen2 = () => setOpenModal2(true);
  const handleClose2 = () => setOpenModal2(false);

  useEffect(() => {
    props.fetchFAQ();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-row w-10/12 items-center justify-between mx-4 my-1">
          <Tooltip title="Politika privatnosti">
              <IconButton
                onClick = {handleOpen1}
                sx={styles.iconButtonStyle}
              >
              <InfoIcon />
              </IconButton>
          </Tooltip>

          <Box sx={styles.typographyStyle}>
            <Typography variant="subtitle2">Često postavljana pitanja</Typography>
          </Box>

          <Box>
            <Tooltip title="Ko ima pristup mojim podacima?">
              <IconButton
                onClick = {handleOpen2}
                sx={styles.iconButtonStyle}
                >
              <BusinessIcon/>
              </IconButton>
            </Tooltip>
          </Box>
      </div>
      <Box sx={styles.mainStyle}>
        {props.FAQList.map((QAItem, index) => {
          return (
            <QACard QAItem={QAItem} key={index} index={index + 1} />
          )
        })}
      </Box>

      <PolitikaPrivatnostiModal 
        open={openModal1}
        handleClose={handleClose1}
      />

      <KompanijeModal
        open={openModal2}
        handleClose={handleClose2}
      />

    </div>

    
  );
}

const mapStateToProps = (state) => {
  return { FAQList: state.faqList.faq }
};

export default connect(mapStateToProps, { fetchFAQ })(FAQ);