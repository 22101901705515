import {
  CircularProgress,
  IconButton,
  Box
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from "react";
import JF2023NoviLogo from "../../assets/JF2023.png";
import resetPassword from "../../assets/resetPassSlika.png";
import LogoBest from "../../assets/BESTNis-tr.png";
import history from "../../common/history";
import { resetUserPassword } from "./ResetPasswordActions";
import { connect } from "react-redux";

const styles = {
  background: {
    backgroundColor: "F4EBEB",
    height: "100vh",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    backgroundSize: "contain",
  },

  bigContainer: {
    backgroundColor: "F4EBEB",
    align:"ceter",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingLeft: "175px",
    paddingTop: "35px",
    borderRadius: "10px",
  },
  
  mainContainer: {
    align:"right",
    width: "490px",
    display: "flex",
    paddingLeft: "200px",
    paddingTop: "25px",
    flexDirection: "column",
    borderRadius: "10px",
  },

  logoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems:"center",
    marginBottom: '60px'
  },
  
  error: {
    color: "red",
    textAlign: 'center',
    marginTop: '10px'
  },
  iconContainer: {
    margin: "6px",
    width: '100%',
  },
  spinner: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
};


const ResetPassword = (props) => {

  const [title, setTitle] = useState("CV App");
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(false);

  const [data, setData] = useState({
    password: "",
    confirmPassword: ""
  });

  useEffect(() => {
    setTitle(`Oporavak lozinke | CV App`);
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (data.password === data.confirmPassword) {
      //console.log("Nova šifra: ", data.password);
      props.resetUserPassword(data.password, window.location.href.split("/").pop())
      alert("Vaša nova šifa je uneta, prijavite novom šifrom.");
      history.push('/login');
    } else {
      setError(true);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full bg-FFFDFA">
        <div className="hidden sm:flex flex-col justify-center h-screen">
            <img className="max-w-[450px] w-full m-auto max-h-[450px]" src={resetPassword} alt=""></img>
        </div>
        <div className="bg-FFFDFA flex flex-col justify-center">
          <form className="max-w-[550px] w-full mx-auto p-4"
                onSubmit={handleSubmit}>
            <div className="flex justify-end mb-5">
              <IconButton type="button" class="text-white bg-gradient-to-r from-blue-500 via-blue-600
                    to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300
                    dark:focus:ring-blue-800 font-medium text-m rounded-full p-2 text-center" onClick={() => history.push('/login')}>
                  <CloseIcon style={{ color: 'white' }} />
              </IconButton> 
            </div>
            <a href="https://jobfairnis.rs/" target="_blank" rel="noreferrer">
              <img className="max-w-[350px] w-full mx-auto" src={JF2023NoviLogo} alt=""></img>
            </a>
            <div class="my-6">
              <input type="password" id="newPassword" name="password" class="bg-slate-50 border
               border-gray-300 text-gray-900 text-m rounded-[10px]
               focus:ring-blue-500 focus:border-blue-500 block w-full
                p-3.5 dark:EDEDED" placeholder="Nova šifra"
                required
                value={data.password}
                onChange={handleInputChange}
              />
            </div>
            <div class="my-6">
              <input type="password" id="confirmNewPassowrd" name="confirmPassword" class="bg-slate-50 border
               border-gray-300 text-gray-900 text-m rounded-[10px]
               focus:ring-blue-500 focus:border-blue-500 block w-full
                p-3.5 dark:EDEDED" placeholder="Potvrdite novu šifru"
                required
                value={data.confirmPassword}
                onChange={handleInputChange}
              />
            </div>
            {!spinner ? (
              <button type="submit" class="text-white bg-gradient-to-r from-blue-500 via-blue-600
              to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300 w-full
              dark:focus:ring-blue-800 font-medium rounded-lg text-m p-3 text-center mr-2 mb-1"
              > Promeni šifru </button>
            ) : (
              <Box sx={styles.spinner}>
                <CircularProgress sx={{ color: "#1b75bc" }} />
              </Box>
            )}
            {!error ? (
              <div></div>
            ) : (
              <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 mt-1 px-4 py-2 rounded cursor-default" role="alert">
                <p class="font-bold">UPOZORENJE!</p>
                <p>Šifre se ne poklapaju, probajte ponovo.</p>
              </div>
            )}

            <a href="https://bestnis.rs/" target="_blank" rel="noreferrer">
              <img className="max-w-[130px] w-full mx-auto pt-2" src={LogoBest} alt=""></img>
            </a>
          </form>
        </div>
    </div>
    /* <Box sx={styles.background}>  
      <Box sx={styles.bigContainer}>
        <Box sx={{ display: "flex", justifyContent: "left" }}>
            <img src={resetPassSlika} width="500" height="500" alt="forgot password" />
        </Box>
        <Box sx={styles.mainContainer}>
          <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
              <img src={JF22OriginalniLogo} width="455" className="JFLogoImg" alt="logo" />
          </Box>
          <form onSubmit={handleSubmit}>
            <Box sx={styles.inputField}>
              <TextField
                type="password"
                placeholder="Nova šifra"
                name="password"
                value={data.password}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>
            <Box sx={styles.inputField}>
              <TextField
                type="password"
                placeholder="Potvrdite novu šifru"
                name="confirmPassword"
                value={data.confirmPassword}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>
            {error && (
              <Typography variant={"body1"} sx={styles.error}>
                {error}
              </Typography>
            )}
            <Button type="submit" variant="contained" sx={{ borderRadius: "10px", backgroundColor: "#1b75bc", color: "white", marginTop: "20px" }}>
              Potvrdi
            </Button>
          </form>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <img src={LogoBest} width="225" />
          </Box>
        </Box>
      </Box>
    </Box> */
  );
};

const mapStateToProps = (state) => {
  return {
    resetUserPassword: state.resetUserPassword,
  };
};

export default connect(mapStateToProps, { resetUserPassword })(ResetPassword);
