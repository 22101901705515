const initialState = { step: 0 };

const RegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_STEP': {
            return Object.assign({}, state, { step: action.payload })
        }
        default: return state;
    }
}
export default RegisterReducer;