import * as React from 'react';
import { Box, Typography, Modal, Paper, Divider, Button, Link } from '@mui/material';

const styles = {
  modalStyle: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    boxShadow: 24
  }
};

const PolitikaPrivatnostiModal = (props) => {

  const handleCloseAction = () => {
    props.handleClose();
  }

    return (  
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-PP-title"
            aria-describedby="modal-PP-description"
            sx={{zIndex: '1800'}}
          >

            <Paper variant="outlined" sx={styles.modalStyle}>
              <div className="flex flex-col justify-center max-h-[500px] px-4 py-2">

                <div className="flex justify-center items-center px-4 py-2 text-blue-500 sm:text-xs">
                  <p className="text-s sm:text-lg font-semibold">Politika Privatnosti - BEST Nis</p>
                </div>

                <Divider />

                <div className="flex justify-start text-justify px-4 py-2 overflow-y-auto">
                  <p>
                    Udruženje studenata tehnike Evrope - Niš (u daljem tekstu: BEST Niš) poštuje Vaše pravo da znate kako se Vaši podaci obrađuju na sajtu jobfairnis.rs.
                    BEST Niš, Elektronski fakultet, Aleksandra Medvedeva 14, 18000 Niš,
                    Republika Srbija, matični broj: 17711199, PIB: 105043242 smatra se
                    rukovaocem Vašim ličnim podacima koje obrađuje na internet stranici
                    jobfairnis.rs, odlučuje o svrsi i načinu obrade podataka o ličnosti koji se ostave
                    na sajtu. Imajući u vidu napred navedeno, to nam daje pravo da obrađujemo
                    Vaše podatke, ali isto tako i imamo obavezu da ih štitimo u skladu sa
                    pozitivno-pravnim propisima Republike Srbije i Evropske Unije kojima se
                    reguliše zaštita podataka o ličnosti, kao i ovom Politikom privatnosti.
                    Za sve dodatne informacije i obaveštenja o Vašoj privatnosti na sajtu
                    jobfairnis.rs, zaštiti podataka o ličnosti i ostvarivanja Vaših prava
                    obratite nam se slanjem e-maila na adresu nis@best-eu.org.
                    Politika privatnosti ima svrhu da svim posetiocima našeg sajta omogući
                    upoznavanje sa načinom na koji se njihovi podaci prikupljaju, koriste i na
                    druge načine obrađuju kada koriste jobfairnis.rs internet stranicu. Mi vodimo
                    računa o zaštiti vaših ličnih podataka i u potpunosti podržavamo prava vas kao
                    korisnika i posetioca sajta jobfairnis.rs
                    Ova Politika privatnosti je usklađena sa Zakonom o zaštiti podataka o
                    ličnosti (“Sl. glasnik RS”, br. 87/2018) kao i sa GDPR-om (Opšta regulativa o
                    zaštiti podataka o ličnosti) tako da su posetiocima iz EU (Evropske unije)
                    takođe zaštićeni podaci onako kako propisuje zakonodavstvo na teritoriji na
                    kojoj žive.
                    Pre pristupanja sajtu jobfairnis.rs ili ostavljanja bilo kojih ličnih podataka,
                    upozoravamo Vas da se upoznate sa ovom politikom privatnosti, te ukoliko sa
                    istom niste saglasni molimo Vas da napustite našu internet stranicu.
                  </p>
                </div>

                <Divider />

                <div className='flex justify-between items-center px-4 py-2'>
                  
                <div className="flex flex-row justify-center text-gray-800">
                  <a href="https://jobfairnis.rs/politika-privatnosti/" 
                     rel="noreferrer" target="_blank" className="font-medium text-blue-700 dark:text-blue-500 hover:underline cursor-pointer">
                    Saznaj više.
                  </a>
                </div>
                    
                <Box sx={styles.actionButtonsBoxSmall}>
                  <Button onClick={handleCloseAction} sx={styles.buttonStyle} variant="outlined">Nazad</Button>
                </Box>

                </div>

              </div>
            </Paper>
              
          </Modal>
        );

}

export default PolitikaPrivatnostiModal;