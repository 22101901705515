import React, { useEffect, useState } from "react";
import "./preview.scss";
import { Typography, Box, Button } from "@mui/material";
import LicniPodaci from '../../components/LicniPodaci/LicniPodaci';
import history from '../../common/history';
import { updateCurrentHistory } from "../../components/HeaderMenu/HeaderActions";
import { updatePreviewObject } from "./PreviewActions"
import { connect } from "react-redux";

const styles = {
  marginTop: {
    marginTop: "1%",
  }
};

const Preview = (props) => {

  useEffect(() => {
    props.updatePreviewObject();
  }, []);

  return (
    <div>Profile preview</div>
    /* <Box sx={styles.marginTop}>
      <Typography variant="h2">Prikaz</Typography>
      <Button
        variant={"contained"}
        color="primary"
        onClick={() => { history.push('/'); props.updateCurrentHistory('/'); }}
      >Edit</Button>

      <LicniPodaci licniPodaci={props.userObject} preview={true}></LicniPodaci>
    </Box> */
  );
};

const mapStateToProps = (state) => {
  return {
      CurentHistory: state.headerObject.currentHistory,
      userObject: state.previewPageObject,
  };
};

export default connect(mapStateToProps, { updateCurrentHistory, updatePreviewObject })(Preview);