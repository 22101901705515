import * as userService from '../../store/userService';

//import {verifyAccount} from '../../store/userActions';

export const sendVerifyAccount = (token) => async dispatch => {
    console.log("token", token);
    userService.verifyAccount({token: token}).then((data) => { 
        console.log(data);
        window.location.replace('/');
    });
}