import * as userService from '../../store/userService';

import {resetPassword} from '../../store/userActions';

export const resetUserPassword = (password, token) => async dispatch => {
    let credentials = {
        "password": password,
        "token": token
      }
    //console.log("resetUserPassword-credentials", credentials);
    userService.resetPassword(credentials).then((data) => { 
        //console.log("resetUserPassword-data", data);
        dispatch(resetPassword);
    });
}