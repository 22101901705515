import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Tooltip,
  Box,
  IconButton,
  Divider
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DescriptionIcon from '@mui/icons-material/Description';

import Avatar from '@mui/material/Avatar';
import CVModal from "../LicniPodaciModals/CVModal";

const styles = {
  paperStyle: {
    display: 'flex',
    flexDirection: 'column',
    //maxWidth: '1100px',
    maxWidth: '64rem',/* 1024px */
    width: '85%',
    flexWrap: 'wrap',
    padding: '24px',
    marginTop: '1.5%',
    borderRadius: '10px'
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '2%',
  },
  contentStyle0: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1%',
  },
  buttonsStyle: {
    display: 'flex',
    justifyContent: 'right',
    flexGrow: 1
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  headingStyle: {
    textAlign: 'start',
    marginBottom: '1%',
    color: 'primary.main',
    fontSize: '20px',
  },
  inputBox1Style: {
    display: 'flex',
    flexDirection: 'row'
  },
  inputBox2Style: {
    display: 'flex',
    flexDirection: 'row',
    border: '2px dashed #1B75BC',
    cursor: 'pointer',
    borderRadius: '8px',
    alignItems: 'center',
  },
  input: {
    cursor: 'pointer',
  },
  typographyStyle: {
    textAlign: 'start', 
    marginRight: '3px', 
    fontSize: 14,
    textAlign: 'left',
    textDecoration: 'underline'
  }
}
const CVUploadCard = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  //useEffect(()=>{console.log("CVUploadCard", props);},[props])

  return (
    <Paper variant="outlined" sx={styles.paperStyle}>

      <Box sx={styles.contentStyle0}>
        <Typography sx={styles.headingStyle}>Radna biografija - CV</Typography>

        <Box sx={styles.buttonsStyle}>
          {/* {!props.preview &&
            <Box>
              <Tooltip title="Dodaj">
                <IconButton
                  // onClick = {handleOpen}
                  sx={styles.iconButtonStyle}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
          } */}
        </Box>
      </Box>

      <Divider />
      <Box sx={styles.contentStyle}>

        <Box className="hidden sm:flex items-center mr-[3%]" /* sx={styles.avatarWrapper */ >
          <Avatar sx={{ bgcolor: '#1B75BC', width: 50, height: 50 }} variant="rounded">
            <DescriptionIcon />
          </Avatar>
        </Box>

        <Box className="sm:flex xs:flex-col justify-start flex-13 flex-col" /* sx={styles.dataStyle} */>
        <Typography sx={{...styles.typographyStyle}}>
          <a href={`${props?.CV}`} target="_blank" rel="noreferrer" >
            {
            props?.CV &&
            props?.CV.split("-").pop()
            }
          </a>
          </Typography>
        </Box>

        <Box sx={styles.buttonsStyle}>
          {!props.preview &&
            <Box>
              <Tooltip title="Izmeni">
                <IconButton
                  onClick={handleOpen}
                  sx={styles.iconButtonStyle}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
          }
        </Box>
        {/* props.CV && */
          <CVModal
            handleChange={props.handleChange}
            CV={props.CV}
            open={openModal}
            handleClose={handleClose}
          />
        }
      </Box>
    </Paper>
  );
}

export default CVUploadCard;