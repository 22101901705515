import React from "react";
import {
    Paper,
    Typography,
    Divider,
    Box,
    Tooltip,
    IconButton
  } from "@mui/material";
  import moment from 'moment';
  import EditIcon from '@mui/icons-material/Edit';
  import AddIcon from '@mui/icons-material/Add';
  import { useState } from "react";
import ProjectsModal from "../LicniPodaciModals/ProjectsModal";

import Avatar from '@mui/material/Avatar';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ProjectsModalAdd from "../LicniPodaciModalsAdd/ProjectsModalAdd";

  const styles = {
    paperStyle: {
      display: 'flex',
      flexDirection: 'column',
      //maxWidth: '1100px',
      maxWidth: '64rem',/* 1024px */
      width: '85%',
      flexWrap: 'wrap',
      padding: '24px',
      marginTop: '1.5%',
      borderRadius: '10px'
    },
    contentStyle0: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '1%',
    },
    contentStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '2%',
    },
    dataStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "left",
      flexGrow: 13
    },
    buttonsStyle : {
      display: 'flex',
      justifyContent: 'right',
      flexGrow: 1
    },
    avatarWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '3%',
    },
    headingStyle : {
      textAlign: 'start',
      marginBottom: '1%',
      color: 'primary.main',
      fontSize: '20px',
    },
    dateStyles: {
      textAlign: 'start',
      color: '#9592AE'
    },
    dateStyles1: {
      textAlign: 'start',
    }
  }

const ProjectsCard = (props) => {
  const [currentProject, setCurrentProject] = useState({
    id: 0,
    datumPocetka: "",
    datumZavrsetka: "",
    naziv: "",
    opis: "",
    uloga: ""

  });

  const [openAddModal, setOpenAddModal] = useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const handleOpenUpdateModal = (project) =>  {
    setCurrentProject(project);
    setOpenUpdateModal(true);
  };
  const handleCloseUpdateModal = () => setOpenUpdateModal(false);


    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>
          
          <Box sx={styles.contentStyle0}>
            <Typography sx={styles.headingStyle}> Rad na projektima </Typography>
            
            <Box sx={styles.buttonsStyle}>
              {!props.preview &&
                <Box>
                  <Tooltip title="Dodaj">
                  <IconButton
                    onClick = {handleOpenAddModal}
                    sx={styles.iconButtonStyle}
                  >
                  <AddIcon />
                  </IconButton>
                  </Tooltip>                
                    <ProjectsModalAdd
                      handleAdd={props.handleAdd}
                      userID={props.userID}
                      open={openAddModal}
                      handleClose={handleCloseAddModal}
                    />
                </Box>
              }
            </Box>

          </Box>

          <Divider />
          {props.radNaProjektu && props.radNaProjektu.map((item, index) => (
          <Box key={index} sx={styles.contentStyle}>

            <Box className="hidden sm:flex items-center mr-[3%]" /* sx={styles.avatarWrapper} */ >
              <Avatar sx={{ bgcolor: '#1B75BC', width: 50, height: 50 }} variant="rounded">
              <AccountTreeIcon />
              </Avatar>    
            </Box>

            <Box className="sm:flex xs:flex-col justify-start items-center flex-13" /* sx={styles.dataStyle} */>
         
                    <div className="flex flex-col ">

                    <div className="flex flex-col justify-start sm:mr-6 xs:mr-2">
                      <Typography sx={{textAlign: 'start', fontWeight: '600', fontSize: 14}}>
                        { item.naziv }
                      </Typography>
                      <Typography sx={{textAlign: 'start', fontWeight: '400', fontSize: 13}}>
                        { item.uloga }
                      </Typography>

                      <Typography sx={styles.dateStyles} variant="subtitle2" gutterBottom>
                        {
                          moment(item.datumPocetka).format("MMM YYYY.").toLocaleString() + 
                        " - " 
                        + (moment(item.datumZavrsetka).isValid() ? moment(item.datumZavrsetka).format("MMM YYYY.").toLocaleString() : 'Current')
                        }
                      </Typography>
                    </div>

                    <Typography sx={{...styles.dateStyles1, wordBreak: 'break-word'}} variant="subtitle2" gutterBottom>
                    { item.opis }
                    </Typography>
                    </div>
            </Box>
         
            <Box sx={styles.buttonsStyle}>
              {!props.preview &&
                  <Box>
                    <Tooltip title="Izmeni">
                      <IconButton
                        onClick={() => handleOpenUpdateModal(item)}
                        sx={styles.iconButtonStyle}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
            </Box>
          
          </Box>
          ))}
            <ProjectsModal
               handleChange={props.handleChange}
               handleDelete={props.handleDelete}
               userID={props.userID}
               project={currentProject}
               open={openUpdateModal}
               handleClose={handleCloseUpdateModal}
            />
        </Paper>
     );
}
 
export default ProjectsCard;