import React, { useEffect } from "react";
import { useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Button,
  Tooltip,
  Divider
} from "@mui/material";
/* import LetterAvatar from "lettered-avatar"; */
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import PersonalDataModal from '../LicniPodaciModals/PersonalDataModal';
import ProfilePictureModal from '../LicniPodaciModals/ProfilePictureModal';
import DefaultUserImage from "../../../assets/default-user.jpg";
import BadUserImage from "../../../assets/dummyPFP.jpg";


const styles = {
  paperStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '24px',
    //maxWidth: '1100px',
    maxWidth: '64rem',/* 1024px */
    width: '85%',
    justifyContent: 'space-between',
    borderRadius: '10px'
  },
  contentStyle: {
    display: 'flex',
    justifyContent: "right",
    flexGrow: 5
  },
  avatarWrapper: {
    width: '160px',
    height: '160px',
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  linkedInButton: {
    padding: '0px',
    '&:hover': {
      color: 'primary.main'
    },
  },
  cvButtonStyle: {
    borderRadius: '50px',
    fontSize: '14px'
  },
  profileImage: {
    backgroundColor: '1B75B',
    width: 130,
    height: 130,
    border: '1px solid ',
    borderColor: 'gray',
    cursor: 'pointer'
  },
  hoverImg: {
    '&:hover': {
      zIndex: 10000,
      opacity: '0.7',
      transition: '0.3s ease',
    },
  }

}

const PersonalDataCard = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleOpenPictureModal = () => setOpenPictureModal(true);
  const handleClosePictureModal = () => setOpenPictureModal(false);

  //useEffect(()=>{console.log("props", props);}, [props])

  return (
    <Paper sx={styles.paperStyle} variant="outlined">
      <Box sx={styles.avatarWrapper} >
        <Avatar sx={styles.profileImage} variant="rounded">
        <Box sx={styles.hoverImg} onClick={handleOpenPictureModal}>
          {!!props.licniPodaci.profilnaSlika ?
            //"z-index: 1000, bacgroundColor: rgba(0,0,0,0.5), transition: 0.3s ease"
              <img src={`${props.licniPodaci.profilnaSlika}`} alt="Profilna Slika"></img>
            :
              <img src={DefaultUserImage} alt="Profilna Slika"></img>
          }
          </Box>
        </Avatar>
      </Box>

      <Divider />

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "left", paddingTop: '1%', paddingBottom: '1%' }}>
        <Typography sx={{ fontWeight: 'bold', textAlign: 'start' }} color="primary.main" variant="h5">{props.licniPodaci.ime + " " + props.licniPodaci.prezime /*+ " " + `(${props.licniPodaci.imeRoditelja})`*/}</Typography>
        <Typography variant="subtitle2" sx={{ textAlign: 'start' }}>{moment(props.licniPodaci.datumRodjenja).format("DD. MMM YYYY.").toLocaleString()}</Typography>
        <Typography variant="subtitle1" sx={{ textAlign: 'start', fontWeight: 'bold', color: 'primary.main' }}>Kontakt informacije:</Typography>
        <Typography variant="subtitle2" sx={{ textAlign: 'start', fontWeight: 'bold' }}>{props.kontakt.telefon}</Typography>
        <Typography variant="subtitle2" sx={{ textAlign: 'start', fontWeight: 'bold' }}>{props.email}</Typography>
        <Box sx={{ display: 'flex' }}>
          {props.kontakt.linkedIn.length > 0 &&
            <IconButton sx={styles.linkedInButton} onClick={() => window.open(props.kontakt.linkedIn, '_blank')}>
              <LinkedInIcon />
            </IconButton>
          }
        </Box>
      </Box>
      <Box sx={styles.contentStyle}>
        {/* {!props.preview &&
        <Box>
          <Tooltip title="Dodaj">
            <IconButton
              onClick = {handleOpen}
              sx={styles.iconButtonStyle}
            >
            <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        } */}
        {!props.preview &&
          <Box>
            <Tooltip title="Izmeni">
              <IconButton
                onClick={handleOpen}
                sx={styles.iconButtonStyle}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        }
      </Box>
      {props.licniPodaci && props.kontakt && props.email &&
        <PersonalDataModal
          userID={props.userID}
          handleChange={props.handleChange}
          licniPodaci={props.licniPodaci} kontakt={props.kontakt} email={props.email}
          open={openModal}
          handleClose={handleClose}
        />
      }
        <ProfilePictureModal
          userID={props.userID}
          handleChange={props.handleChangeProfilePicture}
          licniPodaci={props.licniPodaci}
          profilnaSlika={props.licniPodaci.profilnaSlika}
          open={openPictureModal}
          handleClose={handleClosePictureModal}
        />
    </Paper>
  );
}

export default PersonalDataCard;