import * as React from 'react';
import {
    Box,
    Typography,
    Modal,
    Paper,
    Divider,
    Button,
    IconButton
} from '@mui/material';
import { useState } from 'react';
import DefaultUserImage from "../../../assets/default-user.jpg";
import Avatar from '@mui/material/Avatar';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';


const styles = {
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        boxShadow: 24
    },
    contentWraper: {
        overflow: 'auto',
        display: "flex",
        //flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '285px',
        paddingTop: '2%',
        paddingBottom: '2%',
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    labelAndFieldBox: {
        marginBottom: '2%'
    },
    columnStyle: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column'
    },
    rowStyle: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: '2%'
    },
    actionButtonsBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2
    },
    actionButtonsBoxSmall: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    buttonStyle: {
        marginLeft: '2%',
        width: '100%'
    },
    buttonStyle1: {
        height: '2rem',
        fontSize: '12px'
    },
    profileImage: {
        backgroundColor: '1B75B',
        width: 130,
        height: 130,
        border: '1px solid ',
        borderColor: 'gray',
        cursor: 'pointer'
    },
};

const ProfilePictureModal = (props) => {

    const [profilePicture, setProfilePicture] = useState(props.profilnaSlika);
    const [openModalNtf, setOpenModalNtf] = useState(false);
    const handleOpenNtf = () => setOpenModalNtf(true);
    const handleCloseNtf = () => {
        setOpenModalNtf(false);
        props.handleClose();
    }

    const handleSubmit = () => {
        props.handleChange(profilePicture);
        handleOpenNtf();
    }

    const handleCloseAction = () => {
        props.handleClose();
    }

    const onChangePicture = (files) => {
        setProfilePicture(files[0]);
    }

    return (
        <Modal
            open={props.open}
            onClose={handleCloseAction}
            aria-labelledby="modal-pictureModal-data-title"
            aria-describedby="modal-pictureModal-description"
            sx={{ zIndex: '1800' }}
        >
            <Paper variant="outlined" sx={styles.modalStyle}>

            <div className="flex justify-between max-h-[500px] px-4 py-2">

                <div className="flex px-4 py-2 text-blue-500 sm:text-xs">
                    <p className="text-s sm:text-lg font-semibold">Izmenite profilnu sliku</p>
                </div>

                <div className="flex">
                    <IconButton
                        onClick = { handleCloseAction }
                        /* sx={styles.iconButtonStyle} */
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>

            <Divider />

                <Box sx={styles.contentWraper}>
                    <Box sx={styles.columnStyle}>
                        {/* <Typography sx={{ marginBottom: '0.5%', marginRight: '8px' }}>Trenutna profilna slika: </Typography> */}

                        <Box sx={styles.rowStyle}>
                            
                            <Avatar sx={styles.profileImage} variant="rounded">
                                <Box>
                                    {!!props.licniPodaci.profilnaSlika ?
                                        //"z-index: 1000, bacgroundColor: rgba(0,0,0,0.5), transition: 0.3s ease"
                                        <img src={`${props.licniPodaci.profilnaSlika}`} alt="Profilna Slika"></img>
                                        :
                                        <img src={DefaultUserImage} alt="Profilna Slika"></img>
                                    }
                                </Box>
                            </Avatar>
                        </Box>

                        <Box sx={styles.rowStyle}>
                            <Button
                                sx={styles.buttonStyle1}
                                variant="contained"
                                component="label"
                            >
                                Postavi profilnu sliku
                                <input hidden name="filetoupload" type="file" onChange={(e) => onChangePicture(e.target.files)} />
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Divider />

                <Box sx={styles.actionButtonsBox}>
            
                <Box sx={styles.actionButtonsBoxSmall}>
                    
                    <IconButton
                            /* onClick = {handleOpenAddModal} */
                            /* sx={styles.iconButtonStyle} */
                        >
                        <DeleteIcon sx={{ transform: 'scale(1.2)' }} />
                        </IconButton>
                    
                    <Button onClick={handleSubmit} variant="contained">Sačuvaj</Button>
                </Box>
                    
                </Box>

                <SuccessfulEditModal
                    open={openModalNtf}
                    handleClose={handleCloseNtf}
                />

            </Paper>
        </Modal>
    );
}

export default ProfilePictureModal;