import * as React from 'react';
import { Box,
  Typography,
  Modal,
  Paper,
  Divider,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import { useState, useEffect } from 'react';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';


const styles = {
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24
  },
  contentWraper: {
    overflow: 'auto',
    display: "flex",
    flexDirection: 'column',
    height: '285px',
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  labelAndFieldBox: {
    marginBottom: '1.5%'
  },
  actionButtonsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  },
  actionButtonsBoxSmall: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  actionButtonsBoxSmall2: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    marginTop: '10px'
  },
  buttonStyle: {
    marginLeft: '2%',
    width: '100%'
  },
  buttonStyle1: {
    height: '2rem',
    fontSize: '12px'
  }
};


const HigherEducationModal = (props) => {

  const [univerzitet, setUniverzitet] = useState(props.visokoObrazovanje.univerzitet);
  const [fakultet, setFakultet] = useState(props.visokoObrazovanje.fakultet);
  const [smer, setSmer] = useState(props.visokoObrazovanje.smer);
  const [status, setStatus] = useState(props.visokoObrazovanje.status);
  const [grad, setGrad] = useState(props.visokoObrazovanje.grad);
  const [drzava, setDrzava] = useState(props.visokoObrazovanje.drzava);
  const [godinaUpisa, setGodinaUpisa] = useState(props.visokoObrazovanje.godinaUpisa);
  const [id, setId] = useState(props.visokoObrazovanje.id);

  const [openModalNtf, setOpenModalNtf] = useState(false);
  const handleOpenNtf = () => setOpenModalNtf(true);
  const handleCloseNtf = () => 
    {
      setOpenModalNtf(false);
      props.handleClose();
    }

    const handleSubmit = () => {
      let userID = props.userID?.toString();
      let newData = {
        payload: {
          brojPolozenihIspita: 3,
          drzava: drzava,
          espb: 3,
          fakultet: fakultet,
          fieldID: id,
          godinaUpisa: godinaUpisa,
          godineStudija: 2,
          grad: grad,
          id: id,
          prosek: "2.00",
          smer: smer,
          status: status,
          univerzitet: univerzitet,
          userID: userID
        },
        field: 'visokoObrazovanje'
      }
      /* console.log("WTF") */
      props.handleChange(newData);
      handleOpenNtf();
    }

  const handleCloseAction = () => {
    props.handleClose();
  }

  useEffect(() => {
    /* console.log(props.visokoObrazovanje); */
    setUniverzitet(props.visokoObrazovanje.univerzitet);
    setFakultet(props.visokoObrazovanje.fakultet);
    setSmer(props.visokoObrazovanje.smer);
    setStatus(props.visokoObrazovanje.status);
    setGrad(props.visokoObrazovanje.grad);
    setDrzava(props.visokoObrazovanje.drzava);
    setGodinaUpisa(props.visokoObrazovanje.godinaUpisa);
    setId(props.visokoObrazovanje.id);
  },[props.visokoObrazovanje.univerzitet,
    props.visokoObrazovanje.fakultet,
    props.visokoObrazovanje.smer,
    props.visokoObrazovanje.status,,
    props.visokoObrazovanje.grad,
    props.visokoObrazovanje.drzava,
    props.visokoObrazovanje.godinaUpisa,
    props.visokoObrazovanje.id 
    ] )

    return (  
      <Modal
        open={props.open}
        onClose={handleCloseAction}
        aria-labelledby="modal-personal-data-title"
        aria-describedby="modal-PP-description"
        sx={{zIndex: '1800'}}
      >
        <Paper variant="outlined" sx={styles.modalStyle}>

        <div className="flex justify-between max-h-[500px] px-4 py-2">

          <div className="flex px-4 py-2 text-blue-500 sm:text-xs">
            <p className="text-s sm:text-lg font-semibold">Izmenite podatke visokog obrazovanja</p>
          </div>

          <div className="flex">
                <IconButton
                    onClick = { handleCloseAction }
                    /* sx={styles.iconButtonStyle} */
                >
                  <CloseIcon />
                </IconButton>
          </div>
        </div>

        <Divider />

          <Box sx={styles.contentWraper}>

              <Box sx={styles.labelAndFieldBox}>
                <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Naziv univerziteta: </Typography>
                <TextField
                  required
                  id="outlined-required"
                  fullWidth
                  defaultValue={ univerzitet }
                  onChange={(e) => setUniverzitet(e.target.value)}
                  size="small"
                />
              </Box>

              <Box sx={styles.labelAndFieldBox}>
                <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Naziv fakulteta: </Typography>
                <TextField
                  required
                  id="outlined-required"
                  fullWidth
                  defaultValue={ fakultet }
                  onChange={(e) => setFakultet(e.target.value)}
                  size="small"
                />
              </Box>

              <Box sx={styles.labelAndFieldBox}>
                <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Smer: </Typography>
                <TextField
                  required
                  id="outlined-required"
                  fullWidth
                  defaultValue={ smer }
                  onChange={(e) => setSmer(e.target.value)}
                  size="small"
                />
              </Box>

              <Box sx={styles.labelAndFieldBox}>
                <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Status: </Typography>
                <TextField
                  required
                  id="outlined-required"
                  fullWidth
                  defaultValue={ status }
                  onChange={(e) => setStatus(e.target.value)}
                  size="small"
                />
              </Box>

              <Box sx={styles.labelAndFieldBox}>
                <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Država: </Typography>
                <TextField
                  required
                  id="outlined-required"
                  fullWidth
                  defaultValue={ drzava }
                  onChange={(e) => setDrzava(e.target.value)}
                  size="small"
                />
              </Box>

              <Box sx={styles.labelAndFieldBox}>
                <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Grad: </Typography>
                <TextField
                  required
                  id="outlined-required"
                  fullWidth
                  defaultValue={ grad }
                  onChange={(e) => setGrad(e.target.value)}
                  size="small"
                />
              </Box>

              <Box sx={styles.labelAndFieldBox}>
                <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Godina upisa: </Typography>
                <TextField
                  required
                  id="outlined-required"
                  fullWidth
                  defaultValue={ godinaUpisa }
                  onChange={(e) => setGodinaUpisa(e.target.value)}
                  size="small"
                />
              </Box>

          </Box>

          <Divider />

          <Box sx={styles.actionButtonsBox}>
            
            <Box sx={styles.actionButtonsBoxSmall}>
              
              <IconButton
                    /* onClick = {handleOpenAddModal} */
                    /* sx={styles.iconButtonStyle} */
                >
                  <DeleteIcon sx={{ transform: 'scale(1.2)' }} />
                </IconButton>
              
              <Button onClick={handleSubmit} variant="contained">Sačuvaj</Button>
            </Box>
            
          </Box>

          <SuccessfulEditModal 
            open={openModalNtf}
            handleClose={handleCloseNtf}
          />

        </Paper>
      </Modal>
    );
}
 
export default HigherEducationModal;