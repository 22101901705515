import * as React from 'react';
import { Box, Typography, Modal, Paper, Divider, TextField, Button, Link, Slider, MenuItem, FormControl, InputLabel} from '@mui/material';
import { useState } from 'react';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';

const styles = {
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24
  },
  contentWraper: {
    overflow: 'auto',
    display: "flex",
    flexDirection: 'column',
    height: '285px',
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  labelAndFieldBox: {
    marginBottom: '8%',
    marginTop: '1.5%',
    
    
  },
  actionButtonsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  },
  actionButtonsBoxSmall: {
    display: 'flex',
    width: '90%',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  actionButtonsBoxSmall2: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    marginTop: '10px'
  },
  buttonStyle: {
    marginLeft: '2%',
    width: '100%'
  },
  buttonStyle1: {
    height: '2rem',
    fontSize: '12px'
  }
};


const levels = [
  {
    value: 0,
    label: 'Početni',
  },
  {
    value: 25,
    label: 'Srednji',
  },
  {
    value: 50,
    label: 'Viši',
  },
  {
    value: 100,
    label: 'Napredni',
  },
];


const LanguageModalAdd = (props) => {
    
    const [jezik,setJezik] = useState('');
    const [nivoCitanja,setNivoCitanja] = useState('Početni');
    const [nivoGovora,setNivoGovora] = useState('Početni');
    const [nivoPisanja,setNivoPisanja] = useState('Početni');
    const [nivoRazumevanja,setNivoRazumevanja] = useState('Početni');

    const [openModalNtf, setOpenModalNtf] = useState(false);
    const handleOpenNtf = () => setOpenModalNtf(true);
    const handleCloseNtf = () => {
      setOpenModalNtf(false);
      props.handleClose();
    }

    const handleSubmit = () => {
      let userID = props.userID.toString();
      let newData = {
        payload: {
          jezik: jezik,
          nivoCitanja: nivoCitanja,
          nivoPisanja: nivoPisanja,
          nivoGovora: nivoGovora,
          nivoRazumevanja: nivoRazumevanja,
          fieldID: null,
          id: null,
          sertifikat: "",
          userID: userID
        },
        field: 'poznavanjeJezika'
      }
      props.handleAdd(newData);
      handleOpenNtf();
      resetState();
    }

    const resetState = () => {
      setJezik('');
      setNivoCitanja('');
      setNivoPisanja('');
      setNivoGovora('');
      setNivoRazumevanja('');
    }

   const parseSliderValue = (value) => {
      switch(value) {
        case 0 : return "Početni";
        case 25: return "Srednji";
        case 50: return "Viši";
        case 100: return "Napredni";
      }
    }
  
    const handleCloseAction = () => {
      props.handleClose();
      resetState();
    }

    return (  
      <Modal
      open={props.open}
      onClose={handleCloseAction}
      aria-labelledby="modal-personal-data-title"
      sx={{zIndex: '1800'}}
    >
      <Paper variant="outlined" sx={styles.modalStyle}>
      <div className="flex flex-col justify-center max-h-[500px] px-4 py-2">

        <div className="flex justify-center items-center px-4 py-2 text-blue-500 sm:text-xs">
          <p className="text-s sm:text-lg font-semibold">Dodajte podatke o jezicima</p>
        </div>

        <Divider />
      </div>

      <Box sx={styles.contentWraper}>

        <Box sx={styles.labelAndFieldBox}>
          <Typography sx={{marginBottom: '1%', fontSize: '15px'}}> Jezik: </Typography>
          <TextField
            required
            id="outlined-required"
            fullWidth
            defaultValue={jezik}
            onChange={(e) => setJezik(e.target.value)}
            size="small"
          />
        </Box>

        <Box sx={styles.labelAndFieldBox}>
        <Typography sx={{marginBottom: '1%', fontSize: '15px'}}> Nivo čitanja: </Typography>
          <Slider
            aria-label="Restricted values"
            defaultValue={0}
            step={null}
            marks={levels}
            onChange={(e) => {
              let parsedValue = parseSliderValue(e.target.value);
              setNivoCitanja(parsedValue)
            }}
            valueLabelDisplay = 'off'
           />
        
        </Box>

        <Box sx={styles.labelAndFieldBox}>
          <Typography sx={{marginBottom: '1%', fontSize: '15px'}}> Nivo pisanja: </Typography>
          <Slider
            aria-label="Restricted values"
            defaultValue={0}
            step={null}
            marks={levels}
            onChange={(e) => {
              let parsedValue = parseSliderValue(e.target.value);
              setNivoPisanja(parsedValue)
            }}
            valueLabelDisplay = 'off'
           />
        </Box>

        <Box sx={styles.labelAndFieldBox}>
          <Typography sx={{marginBottom: '1%', fontSize: '15px'}}> Nivo razumevanja: </Typography>
          <Slider
            aria-label="Restricted values"
            defaultValue={0}
            step={null}
            marks={levels}
            onChange={(e) => {
              let parsedValue = parseSliderValue(e.target.value);
              setNivoRazumevanja(parsedValue)
            }}
            valueLabelDisplay = 'off'
           />
        </Box>

        <Box sx={styles.labelAndFieldBox}>
          <Typography sx={{marginBottom: '1%', fontSize: '15px'}}> Nivo govora: </Typography>
          <Slider
            aria-label="Restricted values"
            defaultValue={0}
            step={null}
            marks={levels}
            onChange={(e) => {
              let parsedValue = parseSliderValue(e.target.value);
              setNivoGovora(parsedValue)
            }}
            valueLabelDisplay = 'off'
           />
        </Box>
      </Box>

          <Divider />

          <Box sx={styles.actionButtonsBox}>
              <Box sx={styles.actionButtonsBoxSmall}>
                <Button sx={styles.buttonStyle} onClick={handleSubmit} variant="contained">Dodaj</Button>
                <Button onClick={handleCloseAction} sx={styles.buttonStyle} variant="outlined">Poništi</Button>
              </Box>
          </Box>
          
        <SuccessfulEditModal
          open={openModalNtf}
          handleClose={handleCloseNtf}
        />

      </Paper>
    </Modal>
    );
}
 
export default LanguageModalAdd;