export const validateValue = (value, validateFunctions) => {
    let validity = true;
    let errorMessages = [];

    validateFunctions.forEach((validateFunction) => {
      let validateFuctionReturn = validateFunction(value);
      //console.log("validateFuctionReturn", validateFuctionReturn);
      if (!validateFuctionReturn.isValid) {
        validity = false;
        errorMessages.push(validateFuctionReturn.message);
      }
    });

    return {
      'isValueValid': validity,
      'errorMessages': errorMessages
    }
  }