import {
  CircularProgress,
  Box
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { login } from "./LoginActions";
import JF2023NoviLogo from "../../assets/JF2023.png";
import LogoLeft from   "../../assets/LogoKontrakt.svg";
import LogoBest from "../../assets/BESTNis-tr.png";
import history from "../../common/history";
import LoginErrorModal from "../../components/genericModals/LoginErrorModal";

const styles = {
  spinner: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  error: {
    color: "red",
    textAlign: 'center',
    marginTop: '10px'
  },
}

const Login = (props) => {

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(false);
  const [title, setTitle] = useState("CV App");

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    setTitle(`Log in | CV App`);
    /* props.updateEditObject(); */
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleKeyPress = useCallback(event => {
    if (event.keyCode === 13) {
      submit();
    }
  }, [data])

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress)
    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleKeyPress])

  const submit = () => {
    setSpinner(true);
    setError(false);
    login(data)
      .then((res) => {
        setSpinner(false)
        props.onSuccessfulLogin(res.token);
      })
      .catch((error) => {
        setError(true);
        setSpinner(false);
        handleOpen();
      })
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full bg-FFFDFA">
        <div className="hidden sm:flex flex-col justify-center h-screen">
            <img className="max-w-[470px] w-full m-auto h-screen" src={LogoLeft} alt=""></img>
        </div>
        <div className="bg-FFFDFA flex flex-col justify-center">
          <form className="max-w-[550px] w-full mx-auto p-4">
            <a href="https://jobfairnis.rs/" target="_blank" rel="noreferrer">
              <img className="max-w-[350px] w-full mx-auto" src={JF2023NoviLogo} alt=""></img>
            </a>
            <div class="my-6">
              <input type="email" id="email" className="bg-slate-50 border
               border-gray-300 text-gray-900 text-m rounded-[10px]
               focus:ring-blue-500 focus:border-blue-500 block w-full
                p-3.5 dark:EDEDED" placeholder="Email adresa"
                required 
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}/>
            </div>
            <div class="my-6">
              <input type="password" id="password" class="bg-slate-50 border
               border-gray-300 text-gray-900 text-m rounded-[10px]
               focus:ring-blue-500 focus:border-blue-500 block w-full
                p-3.5 dark:EDEDED" placeholder="Šifra"
                required
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })} />
            </div>

            {!spinner ? (
              <button type="button" class="text-white bg-gradient-to-r from-blue-500 via-blue-600
              to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300 w-full
              dark:focus:ring-blue-800 font-medium rounded-lg text-m p-3 text-center mr-2 mb-1"
              onClick={() => submit()}> Ulogujte se </button>
            ) : (
              <Box sx={styles.spinner}>
                <CircularProgress sx={{ color: "#1b75bc" }} />
              </Box>
            )}
            {!error ? (
              <div></div>
            ) : (
              <LoginErrorModal 
                open={openModal}
                handleClose={handleClose}
              />
              /* <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 rounded cursor-default" role="alert">
                <p class="font-bold">UPOZORENJE!</p>
                <p>Pogrešan mejl ili šifra, probajte ponovo.</p>
              </div> */
            )} 

            <div className="flex flex-row justify-center mt-3 text-gray-800">
              <p className="mr-1 cursor-default">Nemate nalog?</p>
              <p href="#" class="font-medium text-blue-700 dark:text-blue-500 hover:underline cursor-pointer"
              onClick={() => history.push('/register')}>Registujte se!</p>
            </div>
            <div className="flex flex-row justify-center text-gray-800">
              <p href="#" rel="noreferrer" class="font-medium text-blue-700 dark:text-blue-500 hover:underline cursor-pointer"
              onClick={() => history.push('/forgotPassword')}>Zaboravili ste lozinku?</p>
            </div>

            <a href="https://bestnis.rs/" target="_blank" rel="noreferrer">
              <img className="max-w-[130px] w-full mx-auto pt-2" src={LogoBest} alt=""></img>
            </a>
          </form>
        </div>
    </div>
    
  );
};

export default Login;