import * as React from 'react';
import { Box, Typography, Modal, Paper, Divider, TextField, Button, Link} from '@mui/material';
import history from "../../common/history";

const styles = {
    modalStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      boxShadow: 24,
      paddingBottom: '10px'
    },
    contentWraper: {
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100px',
      paddingTop: '2%',
      paddingBottom: '2%',
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    labelAndFieldBox: {
      marginBottom: '2%'
    },
    labelAndFieldBoxLAST: {
      display: "flex",
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row'
    },
    actionButtonsBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      p: 2
    }
};

const CreatedAccModal = (props) => { 
  
    const handleCloseAction = () => {
      props.handleClose();
    }

    return ( 
        <Modal
          open={props.open}
          onClose={handleCloseAction}
          aria-labelledby="modal-personal-data-title"
          sx={{zIndex: '1800'}}
        >
            <Paper variant="outlined" sx={styles.modalStyle}>
                
              
                <div class="flex flex-col bg-green-100 border-green-500 text-green-700 p-4 rounded cursor-default" role="alert">
                  <p class="font-bold">PAŽNJA!</p>
                  <p>Link za aktivaciju Vašeg naloga je poslat na prethodno upisanu e-mail adresu. Proverite svoje sanduče! 💌</p>
                </div>
               

                <Divider />

                <Box sx={styles.actionButtonsBox}>
                    <Box className='flex justify-end' /* sx={styles.actionButtonsBoxSmall} */>
                        <Button onClick={() => history.push('/login')} variant="outlined">Nazad</Button>
                    </Box>
                </Box>

            </Paper>
        </Modal>
    );
}
         
export default CreatedAccModal;