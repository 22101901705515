import HelpIcon from '@mui/icons-material/Help';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LanguageIcon from '@mui/icons-material/Language';

export const menuItems = {
    root: [
        {
            item: {
                icon: (<LanguageIcon />),
                allowed: true,
                name: 'BEST Niš',
                text: 'Menu.Edit',
                beginGroup: false,
                itemData: '/',
            },
            children: []
        },
        {
            item: {
                icon: (<AccountBoxIcon />),
                allowed: true,
                name: 'Moj profil',
                text: 'Menu.Edit',
                beginGroup: false,
                itemData: '/',
            },
            children: []
        },
        /* {
            item: {
                icon: (<PreviewIcon />),
                allowed: true,
                name: 'Prikaži CV',
                text: 'Menu.Preview',
                beginGroup: false,
                itemData: '/preview',
            },
            children: []
        }, */
        {
            item: {
                icon: (<HelpIcon />),
                allowed: true,
                name: 'Pitanja',
                text: 'Menu.FAQ',
                beginGroup: true,
                itemData: '/faq',
            },
            children: []
        },

    ]
}