import axiosService from '../../axiosService/axiosService'
import cookie from 'js-cookie'
//import { cookieDomain } from '../../axiosService/serverSource';

export async function login(credentials) {

  try {
    const res = await axiosService.post('/users/auth', credentials);

    const { data } = res;
 
    //This is shit code because the server we made initially is shit 
    if (data.status && data.status === 409) throw res;
    if (data.status && data.status > 301) throw res;

    cookie.set('token', data.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN })
    cookie.set('id', data.userID, { domain: process.env.REACT_APP_COOKIE_DOMAIN })
    //console.log(data);
    //console.log(data.userID);

    return Promise.resolve(data);
  }
  catch (error) {
    //console.log("LOGIN ERROR", error);

    const {data} = error;

    if (data && data.status === 409) return Promise.reject("LOGIN_WRONG_PASSWORD")


    return Promise.reject("LOGIN_FAILED");
  }
}