export const FETCH_USER = 'FETCH_USER';
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const VERIFY_ACCOUNT = "VERIFY ACCOUNT";
export const VERIFY_ACCOUNT_RESULT = "VERIFY ACCOUNT RESULT";

export const LOGOUT = "LOGOUT"

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const REGISTER_USER_SUCCESS = "REGISTER USER SUCESS";

export const FORGOT_PASSWORD = "FORGOT PASSWORD";
export const FORGOT_PASSWORD_CONFIRM = "FORGOT PASSWORD CONFIRM";
export const FORGOT_PASSWORD_DENIED = "FORGOT PASSWORD DENIED";
export const RESET_PASSWORD = "RESET PASSWORD";

export const IS_USER_LOGGED_IN = "IS USER LOGGED IN";
export const USER_LOGGED_IN_RESULT = "USER LOGGED IN RESULT";

export const INFO_UPDATE_REQUEST = "UPDATE REQUEST";
export const INFO_UPDATE_SUCCESS = "INFO UPDATE SUCESS"

export const OPEN_MODAL = "OPEN MODAL";
export const OPEN_EXPERIENCE_MODAL = "OPEN EXPERIENCE MODAL";
export const SUBMIT_FROM_MODAL = "SUBMIT FROM MODAL";
export const SUBMIT_FROM_MODAL_CALLBACK = "SUBMIT FROM MODAL CALLBACK";

export const CHANGE_PROFILE_PICTURE = "CHANGE PROFILE PICTURE";
export const SEND_PROFILE_PICTURE = "SEND PROFILE PICTURE";
export const CHANGE_CV = "CHANGE CV";

export const FILE_UPLOADED = "FILE UPLOADED";

export const PREPARE_FOR_DELETION = "PREPARE FOR DELETION";
export const SEND_FOR_DELETION = "SEND FOR DELETION";
export const MODAL_DELETED = "MODAL DELETED";

export const SET_MODAL_MESSAGE = "SET MODAL MESSAGE";

export const OLD_ACCOUNT = "OLD ACCOUNT";

