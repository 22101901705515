import userReducer from '../../store/userReducer';
const initialState = userReducer.initialState;

const EditReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_EDIT_OBJECT': {
            return Object.assign({}, state, action.payload )
        }
        case 'UPDATE_USER_INFO': {
            return Object.assign({}, state, {[action.field]: action.payload})
        }
        case 'UPDATE_PERSONAL_DATA': {
            return Object.assign({}, state, {
                [action.response.contact.field]: action.response.contact.payload,
                [action.response.userInfo.field]: action.response.userInfo.payload
            });
        }
        case 'UPDATE_RESIDENCE': {
            return Object.assign({}, state, {
                [action.response.boraviste.field]: action.response.boraviste.payload,
                [action.response.prebivaliste.field]: action.response.prebivaliste.payload
            });
        }
        case 'UPDATE_HIGHSCHOOL': {
            let newHighschools = state.srednjeObrazovanje;
            let updatedItemID = newHighschools.findIndex(item => item.id === action.response.payload.id);
            newHighschools[updatedItemID] = action.response.payload;
            return Object.assign({}, state, {srednjeObrazovanje: newHighschools});
        }
        case 'ADD_HIGHSCHOOL': {
            let newHighschools = state.srednjeObrazovanje;
            newHighschools.push(action.response.payload);
            return Object.assign({}, state, {srednjeObrazovanje: newHighschools});
        }
        case 'UPDATE_EXPERIENCE': {
            let newItems = state.iskustvo[action.response.field];
            let updatedItemID = newItems.findIndex(item => item.id === action.response.payload.id);
            newItems[updatedItemID] = action.response.payload;
            return Object.assign({}, state, {iskustvo: {...state.iskustvo, [action.response.field]: newItems}});
        }
        case 'ADD_EXPERIENCE': {
            let newItems = state.iskustvo[action.response.field];
            newItems.push(action.response.payload);
            return Object.assign({}, state, {iskustvo: {...state.iskustvo, [action.response.field]: newItems}});
        }
        case 'UPDATE_UNIVERSITY': {
            let newUni = state.visokoObrazovanje;
            let updatedItemID = newUni.findIndex(item => item.id === action.response.payload.id);
            newUni[updatedItemID] = action.response.payload;
            return Object.assign({}, state, {visokoObrazovanje: newUni});
        }
        case 'ADD_UNIVERSITY': {
            let newUni = state.srednjeObrazovanje;
            newUni.push(action.response.payload);
            return Object.assign({}, state, {visokoObrazovanje: newUni});
        }
        case "DELETE_EXPERIENCE" : {
            let newItems = state.iskustvo[action.response.field];
            let removedItemID = newItems.findIndex(item => item.id === action.response.payload.id);
            newItems.splice(removedItemID, 1);
            return Object.assign({}, state, {iskustvo: {...state.iskustvo, [action.response.field]: newItems}});
        }
        case 'CLEAR_STATE': {
            return Object.assign({}, initialState, {});
        }
        default: return state;
    }
}
export default EditReducer;
