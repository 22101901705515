import React from "react";
import { 
  Paper,
  Typography,
  Divider,
  Box,
  Tooltip,
  IconButton
  } from "@mui/material";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import WorkExperienceModal from "../LicniPodaciModals/WorkExperienceModal";
import WorkExperienceModalAdd from "../LicniPodaciModalsAdd/WorkExperienceModalAdd";
import { useState } from "react";

import Avatar from '@mui/material/Avatar';
import WorkIcon from '@mui/icons-material/Work';

const styles = {
  paperStyle: {
    display: 'flex',
    flexDirection: 'column',
    //maxWidth: '1100px',
    maxWidth: '64rem',/* 1024px */
    width: '85%',
    flexWrap: 'wrap',
    padding: '24px',
    marginTop: '1.5%',
    borderRadius: '10px'
  },
  contentStyle0: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1%',
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '2%',
  },
  dataStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    flexGrow: 13
  },
  buttonsStyle : {
    display: 'flex',
    justifyContent: 'right',
    flexGrow: 1
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '3%',
  },
  headingStyle : {
    textAlign: 'start',
    marginBottom: '1%',
    color: 'primary.main',
    fontSize: '20px',
  },
  dateStyles: {
    textAlign: 'start',
    color: '#9592AE'
  },
  descStyle: {
    textAlign: 'start',
    wordBreak: 'break-word'
  }
}

const WorkExperienceCard = (props) => {
  
  const [openAddModal, setOpenAddModal] = useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const [openUpdateModal, setOpenUpdateModal] = useState(-1);
  const handleOpenUpdateModal = (modalID) => setOpenUpdateModal(modalID);
  const handleCloseUpdateModal = () => setOpenUpdateModal(-1);

  return (
    <Paper variant="outlined" sx={styles.paperStyle}>

      <Box sx={styles.contentStyle0}>
        <Typography sx={styles.headingStyle}> Radno iskustvo </Typography>

        <Box sx={styles.buttonsStyle}>
          {!props.preview &&
            <Box>
              <Tooltip title="Dodaj">
              <IconButton
                onClick = {handleOpenAddModal}
                sx={styles.iconButtonStyle}
              >
              <AddIcon />
              </IconButton>
              </Tooltip>
              <WorkExperienceModalAdd
                  userID={props.userID}
                  handleAdd={props.handleAdd}
                  open={openAddModal}
                  handleClose={handleCloseAddModal}
              />
            </Box>
          }
        </Box>
      </Box>

      <Divider />
      {props.radnoIskustvo && props.radnoIskustvo.map((item, index) => (
      <Box key={index} sx={styles.contentStyle}>
      
        <Box className="hidden sm:flex items-center mr-[3%]" /* sx={styles.avatarWrapper} */ >
          <Avatar sx={{ bgcolor: '#1B75BC', width: 50, height: 50 }} variant="rounded">
            <WorkIcon />
          </Avatar>    
        </Box>

        <Box className="sm:flex xs:flex-col justify-start items-center flex-13" /* sx={styles.dataStyle} */>        
            
                <div className="flex flex-col ">

                    <div className="flex flex-col justify-start sm:mr-6 xs:mr-2">
                      <Typography sx={{textAlign: 'start', fontWeight: '600', fontSize: 14}}>
                        { item.kompanija}
                      </Typography>
                      <Typography sx={{textAlign: 'start', fontWeight: '400', fontSize: 13}}>
                        { item.funkcija }
                      </Typography>
                    
                    <Typography sx={styles.dateStyles} variant="subtitle2" gutterBottom>
                      {
                        moment(item.datumPocetka).format("MMM YYYY.").toLocaleString() + 
                      " - " 
                      + (moment(item.datumZavrsetka).isValid() ? moment(item.datumZavrsetka).format("MMM YYYY.").toLocaleString() : 'Current')
                      }
                    </Typography>
                  </div>

                  <Typography sx={styles.descStyle}  variant="subtitle2" gutterBottom>
                    { item.opis }
                  </Typography>
                </div>
        </Box>
          
          <Box sx={styles.buttonsStyle}>
            {!props.preview &&
              <Box>
                <Tooltip title="Izmeni">
                <IconButton
                  onClick = {() => handleOpenUpdateModal(index)}
                  sx={styles.iconButtonStyle}
                >
                <EditIcon />
                </IconButton>
                </Tooltip>
              </Box>
            }
          </Box>
        
          <WorkExperienceModal 
            userID={props.userID}
            handleChange={props.handleChange}
            radnoIskustvo={item}
            open={openUpdateModal === index ? true : false}
            handleClose={handleCloseUpdateModal}
          />
      </Box>
      ))}

    </Paper>
  );
};

export default WorkExperienceCard;
