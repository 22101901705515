import * as React from 'react';
import {
  Box,
  Typography,
  Modal,
  Paper,
  Divider,
  Button,
  Link,
  IconButton
} from '@mui/material';
import { useState } from 'react';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const styles = {
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24
  },
  contentWraper: {
    overflow: 'auto',
    display: "flex",
    //flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '285px',
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  labelAndFieldBox: {
    marginBottom: '2%'
  },
  columnStyle: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  },
  rowStyle: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '2%'
  },
  actionButtonsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  },
  actionButtonsBoxSmall: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    flexDirection: 'row'
  },
  buttonStyle: {
    marginLeft: '2%',
    width: '100%'
  },
  buttonStyle1: {
    height: '2rem',
    fontSize: '12px'
  }
};

const CVModal = (props) => {

  const [CV, setCV] = useState(props.CV);
  const [openModalNtf, setOpenModalNtf] = useState(false);
  const handleOpenNtf = () => setOpenModalNtf(true);
  const handleCloseNtf = () => {
    setOpenModalNtf(false);
    props.handleClose();
  }

  const handleSubmit = () => {
    props.handleChange(CV);
    handleOpenNtf();
  }

  const handleCloseAction = () => {
    props.handleClose();
  }

  const onChangeCV = (files) => {
    setCV(files[0]);
  }

  return (
    <Modal
      open={props.open}
      onClose={handleCloseAction}
      aria-labelledby="modal-CVmodal-data-title"
      aria-describedby="modal-CVmodal-description"
      sx={{ zIndex: '1800' }}
    >
      <Paper variant="outlined" sx={styles.modalStyle}>
        
      <div className="flex justify-between max-h-[500px] px-4 py-2">

      <div className="flex px-4 py-2 text-blue-500 sm:text-xs">
        <p className="text-s sm:text-lg font-semibold">Izmenite podatke radne biografije - CV-ja</p>
      </div>

      <div className="flex">
            <IconButton
                onClick = { handleCloseAction }
                /* sx={styles.iconButtonStyle} */
            >
              <CloseIcon />
            </IconButton>
      </div>
      </div>

<Divider />

        <Box sx={styles.contentWraper}>
          <Box sx={styles.columnStyle}>
            <Box sx={styles.rowStyle}>
              <Typography sx={{ marginBottom: '0.5%', marginRight: '8px' }}>Trenutna radna biografia: </Typography>
              <Typography sx={{ marginBottom: '0.5%' }}>
                {
                  props?.CV &&
                  props?.CV.split("-").pop()
                }
              </Typography>
            </Box>

            <Box sx={styles.rowStyle}>
              <Typography sx={{ marginBottom: '0.5%', marginRight: '8px' }}>Dodaj svoju radnu biografiju: </Typography>
              <Button
                sx={styles.buttonStyle1}
                variant="contained"
                component="label"
              >
                Postavi CV
                <input hidden name="filetoupload" type="file" onChange={(e) => onChangeCV(e.target.files)} />
              </Button>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <Link href="https://jobfairnis.rs/" underline="hover">
                  {'Saznaj više o pokroviteljima ovogodišnjeg JobFair-a ovde.'}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box sx={styles.actionButtonsBox}>
            
            <Box sx={styles.actionButtonsBoxSmall}>
              
              {/* <IconButton>
                  <DeleteIcon sx={{ transform: 'scale(1.2)' }} />
              </IconButton> */}
              
              <Button onClick={handleSubmit} variant="contained">Sačuvaj</Button>
            </Box>
            
          </Box>

        <SuccessfulEditModal
          open={openModalNtf}
          handleClose={handleCloseNtf}
        />

      </Paper>
    </Modal>
  );
}

export default CVModal;