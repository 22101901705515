import React, { useEffect, useState } from "react";
import "./edit.scss";
import { Typography, Button, Box, Tooltip, IconButton } from "@mui/material";
import LicniPodaci from '../../components/LicniPodaci/LicniPodaci';
import history from '../../common/history';
import { updateCurrentHistory } from "../../components/HeaderMenu/HeaderActions";
import { 
  updateEditObject, 
  updateHighschool, 
  updatePersonalData, 
  updateResidence, 
  addHighschool, 
  updateExperience, 
  addExperience, 
  updateUniversity,
  addUniversity,
  updateCV,
  deleteExperience,
  updateProfilePicture
} from "./EditActions";
import { connect } from "react-redux";

const Edit = (props) => {

  /* const styles = {
    pageStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }; */

  const [title, setTitle] = useState("CV App");

  const handlers = {
    updatePersonalData: props.updatePersonalData,
    updateResidence: props.updateResidence,

    addHighschool: props.addHighschool,
    updateHighschool: props.updateHighschool,

    updateCV: props.updateCV,
    updateProfilePicture: props.updateProfilePicture,

    addUniversity: props.addUniversity,
    updateUniversity: props.updateUniversity,

    addExperience: props.addExperience,
    deleteExperience: props.deleteExperience,
    updateExperience: props.updateExperience,
  }

  // useEffect(() => {
  //   console.log("props.route.clearState", props);
  // }, [props]);


  useEffect(() => {
    setTitle(`Moj profil | CV App`);
    props.updateEditObject();
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Box>     
      <LicniPodaci licniPodaci={props.userObject} handlers={handlers} preview={false}></LicniPodaci>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    CurentHistory: state.headerObject.currentHistory,
    userObject: state.editPageObject,
  }; 
};

export default connect(mapStateToProps, 
  { updateCurrentHistory, 
    updateEditObject, 
    updatePersonalData, 
    updateResidence,

    addHighschool,
    updateHighschool,
    updateCV,
    updateProfilePicture,

    addUniversity,
    updateUniversity,

    addExperience,
    updateExperience,
    deleteExperience,

  })(Edit);