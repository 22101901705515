//import _ from 'lodash';
const HeaderReducer = ( state = {currentHistory: "/"}, action ) => {
    switch(action.type) {
        case 'UPDATE_HISTORY': {
            return { ...state, currentHistory: action.payload };
        }
        case 'CLEAR_STATE': {
            //console.log('CLEAR_STATE');
            return {currentHistory: "/"};
        }
        default:
            return state;
    }
}

export default HeaderReducer;