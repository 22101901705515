import React from "react";
import { IconButton } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import StepInfo from "./StepInfo";
import history from "../../common/history";
import LogoBest from "../../assets/BESTNis-tr.png";

const styles = {
  step: {
    //display: 'flex',
    //minHeight: '100%',
    //flexDirection: 'column',
    padding: '0rem 0rem 1rem 0rem',
  },
  content: {
    //height: 'auto',
    //flexGrow: 1,
  },
  footer: {
    //marginTop: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nextStepBtn: {
    backgroundColor: '#1B75BC',
    borderRadius: '50%',
  }
};

const Stepper = (props) => {
  const { currentStep, totalSteps, setCurrentStep, incrementStep, decrementStep } = props
  return (
    <div style={styles.step}>

      <div style={styles.content}>
        {props.children}
      </div>

      {currentStep !== totalSteps - 1 && <div className="flex flex-row justify-center mb-1 text-gray-800">
        <p className="mr-1 cursor-default">Već imate nalog?</p>
        <p href="#" className="font-medium text-blue-700 dark:text-blue-500 hover:underline cursor-pointer"
          onClick={() => history.push('/login')}>Prijavite se!</p>
      </div>
      }

      <div style={styles.footer}>
        <IconButton
          type="button"
          className={(currentStep !== 0) ?
            `text-white hover:bg-gradient-to-br focus:ring-2 
              focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 
              font-medium text-m rounded-full p-2 text-center bg-gradient-to-r
               from-blue-500 via-blue-600 to-blue-700`
               :``
          }
          onClick={() => {
            if (currentStep !== 0)
              decrementStep();
          }}
        >
          <NavigateBeforeIcon style={{ color: 'white' }} />
        </IconButton>
        <StepInfo
          currentStep={currentStep}
          totalSteps={totalSteps}
          setCurrentStep={setCurrentStep}
        />
        <IconButton
          type="button"
          className={(currentStep < totalSteps - 1) ?
            `text-white hover:bg-gradient-to-br focus:ring-2 
            focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 
            font-medium text-m rounded-full p-2 text-center bg-gradient-to-r
             from-blue-500 via-blue-600 to-blue-700`
             :``
          }
          onClick={() => {
            if (currentStep < totalSteps - 1)
              incrementStep();
          }}
        >
          <NavigateNextIcon style={{ color: 'white' }} />
        </IconButton>
      </div>

      <a href="https://bestnis.rs/" target="_blank" rel="noreferrer">
        <img className="max-w-[100px] w-full mx-auto" src={LogoBest} alt=""></img>
      </a>
    </div>
  );
};

export default Stepper