import React from "react";
import {
    Paper,
    Typography,
    Tooltip,
    Box,
    IconButton,
    Divider,
    Chip
  } from "@mui/material";
  import AddIcon from '@mui/icons-material/Add';
  import { useState } from "react";
import SkillsModalAdd from "../LicniPodaciModalsAdd/SkillsModalAdd";

import Avatar from '@mui/material/Avatar';
import ScienceIcon from '@mui/icons-material/Science';

const styles = {
  paperStyle: {
    display: 'flex',
    flexDirection: 'column',
    //maxWidth: '1100px',
    maxWidth: '64rem',/* 1024px */
    width: '85%',
    flexWrap: 'wrap',
    padding: '24px',
    marginTop: '1.5%',
    borderRadius: '10px'
  },
  contentStyle0: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1%',
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '2%',
  },
  dataStyle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: 'wrap',
    justifyContent: "start",
    alignItems: "left",
    flexGrow: 13
  },
  buttonsStyle : {
    display: 'flex',
    justifyContent: 'right',
    flexGrow: 1
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '3%',
  },
  headingStyle : {
    textAlign: 'start',
    marginBottom: '1%',
    color: 'primary.main',
    fontSize: '20px',
  },
  dateStyles: {
    textAlign: 'start',
    color: '#9592AE'
  },
  dateStyles1: {
    textAlign: 'start',
  }
}

const SkillsCard = (props) => {
  const [currentSkill, setCurrentSkill] = useState({
    id: 0,
    naziv: "",
    nivo: "",
    sertifikat: ""
  });

  const [openAddModal, setOpenAddModal] = useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleDelete = (id) => {
    let itemToDelete = {
      payload: {
        id: id
      },
      field: 'radNaRacunaru'
    }
    props.handleDelete(itemToDelete);
  };

    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>

          <Box sx={styles.contentStyle0}>
            <Typography sx={styles.headingStyle}> Veštine </Typography>
          
            <Box sx={styles.buttonsStyle}>
              {!props.preview &&
                <Box>
                  <Tooltip title="Dodaj">
                  <IconButton
                    onClick = {handleOpenAddModal}
                    sx={styles.iconButtonStyle}
                  >
                  <AddIcon />
                  </IconButton>
                  </Tooltip>
                  <SkillsModalAdd
                      userID={props.userID}
                      handleAdd={props.handleAdd}
                      open={openAddModal}
                      handleClose={handleCloseAddModal}
                    />
                </Box>
              }
            </Box>
          </Box>
          

          <Divider />

          <Box sx={styles.contentStyle}>

            <Box className="hidden sm:flex items-center mr-[3%]" /* sx={styles.avatarWrapper} */ >
              <Avatar sx={{ bgcolor: '#1B75BC', width: 50, height: 50 }} variant="rounded">
              <ScienceIcon />
              </Avatar>    
            </Box>
            
            <Box sx={styles.dataStyle}>
              {props.skills && props.skills.map((item, index) => (
                    <Tooltip key={index}  title="Click X to delete!">
                    <Chip
                    sx={{width: 'fit-content', margin: '1%'}}
                    label={`${item.naziv}`}
                    /* onClick={() => handleOpenUpdateModal(item)} */
                    onDelete={() => handleDelete(item.id)}
                  />
                  </Tooltip>
              ))}
            </Box>

          </Box>
        
        </Paper>
     );
}
 
export default SkillsCard;