export const mainURL = process.env.REACT_APP_MAIN_URL;

export const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;

export const users = `${mainURL}/users`

export const register = `${users}/register`

export const login = `${users}/auth`

export const update = `${users}/update`

export const refresh = `${users}/refresh`

export const addField = `${users}/addField`

export const remove =`${users}/remove`

export const verification = `${users}/verify`

export const forgottenPassword = `${users}/sifra`

export const resetPassword = `${users}/newpassword`

export const uploadPicture = `${users}/addpicture`

export const uploadCV = `${users}/addcv`