import React, { useEffect, useState } from 'react';
import {
  Drawer,
  /* AppBar, */
  styled,
  useTheme,
  Button,
  MenuItem,
  Toolbar,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  useMediaQuery,
  Box,
  Tooltip
} from '@mui/material';

import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import JF23WhiteLogo from "../../assets/JF23-beli-cropped.png";
import './HeaderMenu.scss';
import history from '../../common/history';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { menuItems } from '../../common/Menu';
import JFBeliLogo from '../../assets/JF22BeliLogo.png';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { MobileUserMenu } from './MobileUserMenu';
import { updateCurrentHistory } from "../HeaderMenu/HeaderActions";
import { connect } from "react-redux";

const drawerWidth = 240;

const styles = {
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolBarContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  appBar: {
    /* zIndex: (theme) => theme.zIndex.drawer + 1, */
    zIndex: (theme) => (theme.zIndex = 1201),
    minHeight: "50px",
    padding: "0px",
    backgroundColor: "#1b75bc"
  },
  menuButton: {
    marginRight: (theme) => theme.spacing(2),
  },
  headerTitle: {
    display: "flex",
    alignItems: "center"
  },
  drawerContainer: {
    overflowX: 'hidden'
  },
  content: {
    flexGrow: 1,
    paddingTop: (theme) => theme.spacing(8),
    marginLeft: 0,
    //paddingTop: "4.6em"
  },
  headerLogo: {
    cursor: 'pointer',
    maxWidth: 160,
    maxHeight: 75,
  },
  userControls: {
    display: "flex",
    alignItems: "center"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: (theme) => theme.transitions.create("width", {
      easing: (theme) => theme.transitions.easing.sharp,
      duration: (theme) => theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: (theme) => theme.transitions.create("width", {
      easing: (theme) => theme.transitions.easing.sharp,
      duration: (theme) => theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: (theme) => theme.spacing(7) + 1,
    [(theme) => theme.breakpoints.up("sm")]: {
      width: (theme) => theme.spacing(7) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: (theme) => theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...(theme) => theme.mixins.toolbar
  },
  accIcon: {
    height: 32,
    width: 32,
    color: (theme) => theme.palette.primary.contrastText,
    justifyContent: "flex-start"
  },
  avatarWrapperButton: {
    width: '42px',
    height: '42px'
  }
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  //height: "64px",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



/* const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
})); */



const HeaderMenu = (props) => {
  const theme = useTheme();
  const screenWidthMDorDown = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState({});
  const [hamburgerOpen, setHamburgerOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const onExpand = (name) => {
    let newOpenState = open;
    newOpenState[name] = !newOpenState[name];
    setOpen({ ...newOpenState });
  }

  const toggleHamburger = () => {
    return screenWidthMDorDown ? !hamburgerOpen : hamburgerOpen;
  }

  const renderMenu = (children, isKid) => {
    if (children !== undefined) {
      return children.map((subOption, index) => {
        if (subOption.item.allowed) {
          if (subOption.children.length === 0) {
            if (isKid) {
              return (
                <Box key={subOption.item.text}>
                  {index < children.length - 1 && subOption.item.beginGroup ? (
                    <Divider key={subOption.item.text + "Divider"} />
                  ) : (
                    <div></div>
                  )}
                  <ListItem
                    button
                    key={subOption.item.text}
                    onClick={() => {
                      setHamburgerOpen(toggleHamburger);
                      history.push(subOption.item.itemData);
                    }}
                  >
                    {subOption.item.icon && (
                      <ListItemIcon> {subOption.item.icon} </ListItemIcon>
                    )}
                    <ListItemText
                      key={subOption.item.text + "ListItemText"}
                      primary={subOption.item.name}
                      style={{ marginLeft: "2em" }} />
                  </ListItem>
                </Box>
              )
            } else {
              return (
                <Box key={subOption.item.text}>
                  {subOption.item.beginGroup ? (
                    <Divider key={subOption.item.text + "Divider"} />
                  ) : (
                    <></>
                  )}
                  <ListItem
                    button
                    key={subOption.item.text}
                    onClick={() => {
                      history.push(subOption.item.itemData);
                    }}
                  >
                    {subOption.item.icon && (
                      <ListItemIcon> {subOption.item.icon} </ListItemIcon>
                    )}
                    <ListItemText
                      key={subOption.item.text + "ListItemText"}
                      primary={subOption.item.name}
                    />
                  </ListItem>
                </Box>
              );
            }
          }
          return (
            <Box key={subOption.item.name}>
              {subOption.item.beginGroup ? (
                <Divider key={subOption.item.name + "Divider"} />
              ) : (
                <></>
              )}
              <ListItem
                button
                key={subOption.item.name}
                onClick={() => onExpand(subOption.item.name)}
              >
                {subOption.item.icon && (
                  <ListItemIcon> {subOption.item.icon} </ListItemIcon>
                )}
                <ListItemText
                  key={subOption.item.name + "ListItemText"}
                  primary={subOption.item.name}
                />
                {open[subOption.item.name] ? (
                  <ExpandLess key={subOption.item.name + "ExpandLess"} />
                ) : (
                  <ExpandMore key={subOption.item.name + "ExpandMore"} />
                )}
              </ListItem>
              <Collapse
                key={subOption.item.name + "Collapse"}
                in={open[subOption.item.name]}
                timeout="auto"
                unmountOnExit
              >
                <List key={subOption.item.name + "List"}>
                  {renderMenu(subOption.children, true)}
                </List>
              </Collapse>
            </Box>
          );
        } else {
          return (<></>);
        }
      })
    } else {
      return (<></>);
    }
  }

  const handleDrawerToggle = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  // const submitCV = () => {
  //   props.logout();
  //   history.push('/register')
  // }

  // const editCV = () => {
  //   let win = window.open('https://cv.jobfairnis.rs/cvForma', '_blank');
  //   win.focus();
  // }

  const renderDekstopUserMenu = () => {
    return (
      <Box>

        {/* <Button sx={{ color: 'white' }} onClick={() => { history.push('/faq') }}>
          Pitanja
        </Button> */}
        {/* <IconButton onClick={() => { history.push('/') }} >
          <Tooltip title="Moj profil">
            <AccountCircleRoundedIcon sx={styles.accIcon} fontSize="inherit" />
          </Tooltip>
        </IconButton> */}
        <IconButton onClick={handleAccOpen} >
          <MenuIcon sx={styles.accIcon} fontSize="inherit" />
        </IconButton>
        <MobileUserMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleAccClose}
        >
          <MenuItem onClick={() => { history.push('/') }}>Moj profil</MenuItem>
          <MenuItem onClick={() => { history.push('/faq') }}>Često postavljana pitanja</MenuItem>
          <MenuItem onClick={() => { handlePasswordChange() }}>Resetuj šifru</MenuItem>
          <MenuItem onClick={() => { props.clearState(); props.logout();}}>Odjavi se</MenuItem>
        </MobileUserMenu>

      </Box >)
  }

  const handleAccClose = (event) => {
    setAnchorEl(null);
  };

  const handleAccOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePasswordChange = () => {
    props.logout();
    history.push('/forgotPassword');
  }

  return (

    <Box sx={styles.root}>
      <CssBaseline />

      <AppBar id="appBar" position="fixed" sx={styles.appBar}>
        <Toolbar>
            <a href="https://jobfairnis.rs/" target="_blank" rel="noreferrer" >
              <img className="max-w-[150px] w-full mx-auto" src={JF23WhiteLogo} alt=""></img>
            </a>
          <Box sx={styles.toolBarContainer}>
            <Box sx={styles.headerTitle}>
            </Box>
            <Box sx={styles.userControls}>
              {renderDekstopUserMenu()}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={styles.content}>
        {props.childrenComponents}
      </Box>
    </Box>
  );


}

const mapStateToProps = (state) => {
  return {
    CurentHistory: state.headerObject.currentHistory,

  };
};

export default connect(mapStateToProps, {
  updateCurrentHistory,
  clearState: () => async dispatch => {
    //console.log("clearState");
    dispatch({ type: 'CLEAR_STATE' });
  }
})(HeaderMenu);