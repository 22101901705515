import React, { useState, useEffect } from "react";
import { changeStep } from './RegisterActions';
import { connect } from 'react-redux';
import RegisterImage from "../../assets/RegisterImage.svg";
import Stepper from "./Stepper";
import RegisterForm from "./RegisterForm";
import moment from "moment";
import { registerUser } from "./RegisterActions";
import history from "../../common/history";
import CreatedAccModal from "../../components/genericModals/CreatedAccModal";
import { mainInformations, contactInformations, residence1Info, residence2Info, loginInfo } from "./RegisterSteps"
import { validateValue } from "../../helpers/validator/Validator";

const Register = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [currentStep, setCurrentStep] = useState(0)
  const formData = [mainInformations, contactInformations, residence1Info, residence2Info, loginInfo]
  const [forms, setForms] = useState(formData)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [title, setTitle] = useState("CV App");

  const [createdAccNoti, setCreatedAccNot] = useState(false);

  useEffect(() => {
    setTitle(`Registruj se | CV App`);
    /* props.updateEditObject(); */
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  // useEffect(() => {
  //   console.log("forms", forms);
  // }, [forms]);

  const handleFieldUpdate = (fieldName, fieldKey, fieldValue) => {
    setForms((old) => [
      ...old.map((form) => ({
        ...form,
        fields: [
          ...form.fields.map((field) => {
            let newfiled = { ...field };
            if (field.field === fieldName) {
              newfiled[fieldKey] = fieldValue;
              return newfiled;
            } else {
              return newfiled;
            }
          }
          )
        ]
      }))
    ])
  }

  const isStepValid = () => {
    let isStepValid = true;
    forms[currentStep].fields.forEach((field) => {

      if (!!field.validateFunctions) {
        let validationObject = validateValue(field.value, field.validateFunctions);
        if (!validationObject.isValueValid) {
          isStepValid = false;
          handleFieldUpdate(field.field, 'errorMessage', validationObject.errorMessages[0]);
          handleFieldUpdate(field.field, 'state', 'invalid');
        }
      }
    })

    return isStepValid;
  }

  const isPasswordsMatching = () => {
    let arePasswordsMatching = true;
    let password = forms[currentStep].fields.find((field) => { return field.field === 'password' });
    let confirmPassword = forms[currentStep].fields.find((field) => { return field.field === 'confirmPassword' });

    //console.log("password.value", password.value);
    //console.log("confirmPassword.value", confirmPassword.value);
    if (password.value !== confirmPassword.value) {
      arePasswordsMatching = false;
      handleFieldUpdate(confirmPassword.field, 'errorMessage', 'Šifra se ne poklapa');
      handleFieldUpdate(confirmPassword.field, 'state', 'invalid');
    }

    return arePasswordsMatching;
  }

  const handleIncrementStep = () => {
    if (isStepValid()) {
      setCurrentStep((old) => old + 1);
    }
  }

  const handleDecrementStep = () => {
    setCurrentStep((old) => old - 1);
  }

  const handleRegister = () => {
    if (isStepValid()) {
      if (isPasswordsMatching()) {
        const data = [];
        forms.forEach(form =>
          form.fields.forEach(field =>
            data.push(field)
          )
        )
      
      //console.log("data", data);


      const fields = {};
      data.map((field) => fields[field.field] = field.value)
      //console.log("DATA ", JSON.stringify(fields))

      let newObject = {
        "email": fields.email,
        "password": fields.password,
        "licniPodaci": {
          "ime": fields.name,
          "prezime": fields.lastName,
          "imeRoditelja": fields.parentName,
          "datumRodjenja": fields.birthDate
        },
        "kontakt": {
          "telefon": fields.phoneNumber,
          "linkedIn": fields.linkedin
        },
        "prebivaliste": {
          "drzava": fields.residence1Country,
          "grad": fields.residence1City,
          "adresa": fields.residence1Address
        },
        "boraviste": {
          "drzava": fields.residence2Country,
          "grad": fields.residence2City,
          "adresa": fields.residence2Address
        },
        "datumRegistracije": moment().format()
      }
      //console.log("newObject", newObject);
       props.registerUser(newObject);
      alert('Mejl je poslat.');
      //history.push('/login')
      //dodavanje pravog modala koji iskače!
    }
  }
  //handleOpen();
}

const totalSteps = formData.length
return (
  <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full bg-FFFDFA">
    <div className="hidden sm:flex flex-col justify-center h-screen">
      <img className="max-w-[420px] w-full m-auto h-screen" src={RegisterImage} alt=""></img>
      <div className="flex flex-col justify-center my-4 mx-20">
        <h1 className="flex flex-col mb-2 text-4xl font-extrabold leading-none tracking-tight
            lg:text-3xl text-gray-900
            2xl">
          <span>Registruj se i </span>
          <span className="underline underline-offset-2 decoration-8 decoration-blue-400 dark:decoration-blue-600
            lg:text-5xl">ostavi CV!</span>
        </h1>
        <p className="text-lg font-normal text-gray-700
            lg:text-2xl lg:mb-10 lg:mt-2">
          Tvoja karijera počinje ovde!
        </p>
      </div>
    </div>
    <div className="flex flex-col max-w-[550px] w-full mx-auto p-4">
      <div className={`flex-grow`}>
        <RegisterForm formData={forms[currentStep]} handleChange={handleFieldUpdate} />
        {currentStep === (totalSteps - 1) &&
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center mb-1 text-gray-800">
              <input id="link-checkbox" type="checkbox" value=""
                className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded
                        focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-1
                        dark:bg-gray-700 dark:border-gray-600"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)} />
              <label htmlFor="link-checkbox" className="mr-1 cursor-default">Slažem se sa
                <a
                  href="https://drive.google.com/file/d/15uomppZEQcHqXARKsUglMg_CiNfSeGp3/view?usp=share_link" target="_blank" rel="noreferrer"
                  className="font-medium text-blue-700 dark:text-blue-500 hover:underline cursor-pointer">vašom politikom privatnosti</a>.</label>
            </div>

            <button className="text-white bg-gradient-to-r from-blue-500 via-blue-600
                    to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300 w-full
                    dark:focus:ring-blue-800 font-medium rounded-lg text-m p-3 text-center mr-2 my-4
                    disabled:bg-gradient-to-r disabled:from-blue-400 disabled:to-blue-400
                    "
              type="button"
              onClick={handleRegister}
              disabled={!termsAccepted}>
              Kreirajte nalog
            </button>
          </div>
        }
      </div>
      <Stepper
        currentStep={currentStep}
        totalSteps={totalSteps}
        setCurrentStep={setCurrentStep}
        incrementStep={handleIncrementStep}
        decrementStep={handleDecrementStep}
      ></Stepper>

    </div>

    <CreatedAccModal
      open={openModal}
    />

  </div>
);
};

const mapStateToProps = (state) => {
  return {
    RegisterActiveStep: state.registerList.step
  };
};

export const formStyles = {
  headline: {
    fontSize: '2rem',
    fontWeight: 500,
    marginBottom: '2rem',
    color: '#686868',
  },
  /* form: {
        display: 'flex',
      flexDirection: 'column',
      rowGap: '2rem',
  },
      formInput: {
        backgroundColor: '#ededed',
      borderRadius: '10px',
      border: "1px black solid",
      padding: "0.875rem"
  }, */
};

export default connect(mapStateToProps, { changeStep, registerUser })(Register);