import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import history from "../../common/history";

const ajustBorderColor = ((state) => {
  if (state === 'empty') {
    return "border-blue-500"
  } else if (state === 'entered') {
    return "border-gray-500"
  } else if (state === 'valid') {
    return "border-green-500"
  } else if (state === 'invalid') {
    return "border-red-500"
  }
  return "border-purple-500"
})

const RegisterForm = (props) => {

  return (
    <form className="max-w-[550px] w-full mx-auto p-2">
      <div className="flex justify-between">
        <div className="text-3xl font-medium mb-1 text-gray-600">{props.formData.headline}</div>
        <IconButton type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600
              to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300
              dark:focus:ring-blue-800 font-medium text-m rounded-full p-2 text-center" /* style={styles.nextStepBtn} */ onClick={() => history.push('/login')}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
      </div>
      <div className="my-6">
        {props.formData.fields?.map(field => (
          <div key={"div-" + props.formData.id + field.id}>
            <p className="font-medium mb-2 mt-3 text-gray-600 cursor-default"> {field.label} </p>
            <input
              id={props.formData.id + field.id}
              className={`bg-slate-50 border
                text-gray-900 text-m rounded-[10px]
                focus:ring-blue-500 focus:border-blue-500 block w-full
                p-2.5 dark:EDEDED ${ajustBorderColor(field.state)}`}
              required
              key={field.field}
              value={field.value}
              type={field.type}
              placeholder={field.placeholder}
              onChange={(e) => {
                field.state = 'entered';
                props.handleChange(field.field, 'value', e.target.value);
              }}
            />
            { field.state === 'invalid' &&
              <p className="font-medium mb-2 text-red-600 cursor-default">
               {field.errorMessage} 
              </p>
            }
          </div>
        ))}
      </div>
    </form>
  );
};

export default RegisterForm