import './App.css';
import React, { useEffect, useState } from 'react';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import { makeStyles } from '@material-ui/core';
import cookie from 'js-cookie'
import history from './common/history';

import { Box } from "@mui/material";

import Login from './pages/Login/Login';
import HeaderMenu from './components/HeaderMenu/HeaderMenu';
import Nav from './components/HeaderMenu/NavBar';
import Footer from './components/Footer/Footer';
import Edit from './pages/Edit/Edit';
import Preview from './pages/Preview/Preview';
import FAQ from "./pages/FAQ/FAQ";

import Register from './pages/Register/Register';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import AccountVerification from './pages/AccountVerification/AccountVerification';


function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: window.innerHeight - 64,
  },
  content: {
    flex: '1 0 auto',
  },
  snackBarContainer: {
    marginLeft: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const useStyles = makeStyles({});
function App(props) {
  useStyles();
  const [validToken, setValidToken] = useState(null);

  useEffect(() => {
    // CHECK user status here
    //axiosService.get('/users/currentEvent').then(res => console.log("EVENT", res.data))
    const token = cookie.get('token');
    setValidToken(token)

    if (token && (token !== "undefined" || token !== "null")) {
    }
    else {
      // This is temporary hardcoded, auth guard needs improvements on this part
      // if (history.location.pathname !== '/register' && history.location.pathname !== '/login') {
      //   history.push('/login')
      // }
    }
    return () => {
    }
  }, [validToken])

  const logout = () => {
    cookie.remove('token', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    cookie.remove('id', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    setValidToken(undefined)
  }

  const isAuthenticated = () => {
    if (validToken) {
      return (
        <div className={"App"}>
          {/* <Nav childrenComponents={renderContent()} logout={() => logout()} /> */}
          <HeaderMenu childrenComponents={renderContent()} logout={() => logout()} />
        </div>
      )
    }
    else {
      // if (history.location.pathname !== '/login' && history.location.pathname !== '/register') history.replace('/login')
      return (
        <Switch>
          <Route path="/login">
            <Login onSuccessfulLogin={token => {
              setValidToken(token);
              history.push('/');
            }} />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>
          <Route path="/changePassword/:token">
            <ResetPassword />
          </Route>
          <Route path="/verification/:token">
            <AccountVerification />
          </Route>
          <Redirect to="/login" />
        </Switch>
      )
    }
  }

  const renderContent = () => {
    return (
      <Box sx={styles.container}>
        <Box sx={styles.content}>
          <Switch >
            <Route exact path="/" component={Edit} />
            <Route exact path="/preview" component={Preview} />
            <Route path="/faq" component={FAQ} />
            <Redirect to="/" /> 
          </Switch>
        </Box>
        <Footer />
      </Box>
    )
  }

  return (
    <Router history={history}>
      <ScrollToTop />
      {
        validToken !== null &&
        <div>
          {isAuthenticated()}
        </div>
      }
    </Router >
  );
};

export default App;