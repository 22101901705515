import React from "react";
import {
    Paper,
    Typography,
    Box,
    Tooltip,
    IconButton,
    Divider
  } from "@mui/material";
  import EditIcon from '@mui/icons-material/Edit';
import ResidenceModal from "../LicniPodaciModals/ResidenceModal";
import { useState } from "react";

import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home';


const styles = {
  paperStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '24px',
    //maxWidth: '1100px',
    maxWidth: '64rem',/* 1024px */
    width: '85%',
    justifyContent:'space-between',
    alignItems: 'center',
    marginTop: '1.5%',
    borderRadius: '10px'
  },
  contentStyle : {
    display: 'flex',
    justifyContent: "right",
    flexGrow: 15
  },
  headingStyle : {
    textAlign: 'start',
      color: 'primary.main',
      fontSize: '20px',
  },
  avatarWrapper: {
    width: '100px',
    height: '100px',
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  }
}

const ResidenceCard = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>

          <Box className="hidden sm:flex items-center mr-[3%]" >
            <Avatar sx={{ bgcolor: '#1B75BC', width: 50, height: 50 }} variant="rounded">
              <HomeIcon />
            </Avatar>
          </Box>

          <Divider className='hidden sm:flex' orientation="vertical" flexItem/>

            <Box sx={{margin: '1%', flexGrow: 1}}>
              <Typography  sx={styles.headingStyle}>Prebivalište</Typography>
              {props.prebivaliste.adresa.length > 0 &&
              <Typography sx={{textAlign: 'start', fontSize: 14}}>{props.prebivaliste.adresa}</Typography>
              }
              <Typography sx={{textAlign: 'start', fontSize: 14}}>{props.prebivaliste.grad}</Typography>
              <Typography sx={{textAlign: 'start', fontSize: 14}}>{props.prebivaliste.drzava}</Typography>
            </Box>

            <Box sx={{margin: '1%', flexGrow: 1}}>
              <Typography sx={styles.headingStyle}>Boravište</Typography>
              {props.boraviste.adresa.length > 0 &&
              <Typography sx={{textAlign: 'start', fontSize: 14}}>{props.boraviste.adresa}</Typography>
              }
              <Typography sx={{textAlign: 'start', fontSize: 14}}>{props.boraviste.grad}</Typography>
              <Typography sx={{textAlign: 'start', fontSize: 14}}>{props.boraviste.drzava}</Typography>
            </Box>

            <Box sx={styles.contentStyle}>
              {!props.preview &&
              <Box>
                <Tooltip title="Izmeni">
                  <IconButton
                    onClick = {handleOpen}
                    sx={styles.iconButtonStyle}
                  >
                  <EditIcon />
                  </IconButton>
                  </Tooltip>
              </Box>
              }
            </Box>
            {props.boraviste && props.prebivaliste &&
              <ResidenceModal 
                boraviste={props.boraviste} 
                prebivaliste={props.prebivaliste}
                handleChange={props.handleChange}
                userID={props.userID}
                open={openModal}
                handleClose={handleClose} />
            }
          
        </Paper>
     );
}

export default ResidenceCard;