import * as userService from '../../store/userService';
import * as userRoutes from '../../store/routes';
import * as routes from '../../store/routes';

export const updateEditObject = () => async dispatch => {
    userService.fetchUserById().then((data) => {
        //console.log("updateEditObject", data);
        dispatch({ type: 'UPDATE_EDIT_OBJECT', payload: data });
    });
}

export const updateUserInfo = (payload) => async dispatch => {
    userService.updateUserInfo(payload).then((data) => {
        //console.log(data);
        dispatch({ type: 'UPDATE_USER_INFO', payload: data.payload, field: data.field });
    });
}

export const updatePersonalData = (payload) => async dispatch => {
    userService.updateUserInfo(payload.userInfo).then((userInfoData) => {
        //console.log(userInfoData);
        userService.updateUserInfo(payload.contact).then((contactData) => {
            //console.log(contactData);
            dispatch({ type: 'UPDATE_PERSONAL_DATA', response: { contact: contactData, userInfo: userInfoData } });
        });

    });
}

export const updateResidence = (payload) => async dispatch => {
    userService.updateUserInfo(payload.boraviste).then((boravisteRes) => {
        //console.log(boravisteRes);
        userService.updateUserInfo(payload.prebivaliste).then((prebivalisteRes) => {
            //console.log(prebivalisteRes);
            dispatch({ type: 'UPDATE_RESIDENCE', response: { prebivaliste: prebivalisteRes, boraviste: boravisteRes } });
        });

    });
}

export const updateHighschool = (payload) => async dispatch => {
    userService.updateUserInfo(payload).then((response) => {
        //console.log(response);
        dispatch({ type: 'UPDATE_HIGHSCHOOL', response: response });
    });
}

export const addHighschool = (payload) => async dispatch => {
    userService.addField(routes.addField, payload).then((response) => {
        //console.log(response);
        dispatch({ type: 'ADD_HIGHSCHOOL', response: response});
    });
}

export const addUniversity = (payload) => async dispatch => {
    userService.addField(routes.addField, payload).then((response) => {
        //console.log(response);
        dispatch({ type: 'ADD_UNIVERSITY', response: response});
    });
}


export const updateExperience = (payload) => async dispatch => {
    userService.updateUserInfo(payload).then((response) => {
        //console.log(response);
        dispatch({ type: 'UPDATE_EXPERIENCE', response: response});
    });
}

export const deleteExperience = (payload) => async dispatch => {
    userService.removeField(payload).then((response) => {
        //console.log(response);
        dispatch({ type: 'DELETE_EXPERIENCE', response: response});
    });
}

export const addExperience = (payload) => async dispatch => {
userService.addField(routes.addField, payload).then((response) => {
    //console.log(response);
    dispatch({ type: 'ADD_EXPERIENCE', response: response});
});
}

export const updateUniversity = (payload) => async dispatch => {
    userService.updateUserInfo(payload).then((response) => {
        //console.log(response);
        dispatch({ type: 'UPDATE_UNIVERSITY', response: response});
    });
}

export const updateCV = (payload) => async dispatch => {
    userService.sendFile(payload, userRoutes.uploadCV).then((response) => {
        userService.fetchUserById().then((data) => {
            dispatch({ type: 'UPDATE_EDIT_OBJECT', payload: data });
        });
    })
}

export const updateProfilePicture = (payload) => async dispatch => {
    //console.log("updateProfilePicture-payload", payload);
    userService.sendFile(payload, userRoutes.uploadPicture).then((response) => {
        //console.log("response", response);
        userService.fetchUserById().then((data) => {
            //console.log("updateProfilePicture-data", data);
            dispatch({ type: 'UPDATE_EDIT_OBJECT', payload: data });
        });
    })
}