import React from "react";

const styles = {
  previousStep: {
    width: '1.5rem',
    height: '.75rem',
    backgroundColor: '#686868',
    borderRadius: '1rem',
    transition: 'all .4s',
    cursor: 'pointer'
  },
  currentStep: {
    width: '2.5rem',
    height: '.75rem',
    backgroundColor: '#1B75BC',
    borderRadius: '1rem',
    transition: 'all .4s',
  },
  nextStep: {
    width: '1.5rem',
    height: '.75rem',
    backgroundColor: '#DDDDDD',
    borderRadius: '1rem',
    transition: 'all .4s'
  },
  info: {
    display: 'flex',
    columnGap: '.5rem'
  }
};

const ajustStepStyle = (myStep, currentStep) => {
  if (myStep < currentStep) {
    return styles.previousStep
  } else if (myStep === currentStep) {
    return styles.currentStep
  } else if (myStep > currentStep) {
    return styles.nextStep
  }
}

const StepInfo = (props) => {
  return (
    <div style={styles.info}>
      {
        Array(5).fill().map((e, index) =>
          <span 
            key={"StepInfo-"+index}
            onClick={() =>{
              if(index < props.currentStep)
                props.setCurrentStep(index);
            }}
            style={ajustStepStyle(index, props.currentStep)}>
          </span>
        )}
    </div>
  );
};



export default StepInfo