import * as React from 'react';
import { Box,
  Typography,
  Modal,
  Paper,
  Divider,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import { useState, useEffect } from 'react';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';


const styles = {
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24
  },
  contentWraper: {
    overflow: 'auto',
    display: "flex",
    flexDirection: 'column',
    height: '285px',
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  labelAndFieldBox: {
    marginBottom: '1.5%'
  },
  actionButtonsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  },
  actionButtonsBoxSmall: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  actionButtonsBoxSmall2: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    marginTop: '10px'
  },
  buttonStyle: {
    marginLeft: '2%',
    width: '100%'
  },
  buttonStyle1: {
    height: '2rem',
    fontSize: '12px'
  }
};


const HighschoolEducationModal = (props) => {  
  
  const [tip, setTip] = useState(props.srednjeObrazovanje.tip);
  const [naziv, setNaziv] = useState(props.srednjeObrazovanje.naziv);
  const [smer, setSmer] = useState(props.srednjeObrazovanje.smer);
  const [grad, setGrad] = useState(props.srednjeObrazovanje.grad);
  const [drzava, setDrzava] = useState(props.srednjeObrazovanje.drzava);
  const [godinaZavrsetka, setGodinaZavrsetka] = useState(props.srednjeObrazovanje.godinaZavrsetka);
  const [id, setId] = useState(props.srednjeObrazovanje.id);

  const [openModalNtf, setOpenModalNtf] = useState(false);
  const handleOpenNtf = () => setOpenModalNtf(true);
  const handleCloseNtf = () => 
    {
      setOpenModalNtf(false);
      props.handleClose();
    }

  const handleSubmit = () => {
    let userID = props.userID.toString();
    let newData = {
      payload: {
        drzava: drzava,
        fieldID: id,
        id: id,
        godinaZavrsetka: godinaZavrsetka,
        grad: grad,
        smer: smer,
        naziv: naziv,
        tip: tip,
        userID: userID
      },
      field: 'srednjeObrazovanje'
    }
    props.handleChange(newData);
    handleOpenNtf();
  }

  const handleCloseAction = () => {
    props.handleClose();
  }

  useEffect(() => {
    setTip(props.srednjeObrazovanje.tip);
    setNaziv(props.srednjeObrazovanje.naziv);
    setSmer(props.srednjeObrazovanje.smer);
    setGrad(props.srednjeObrazovanje.grad);
    setDrzava(props.srednjeObrazovanje.drzava);
    setGodinaZavrsetka(props.srednjeObrazovanje.godinaZavrsetka);
    setId(props.srednjeObrazovanje.id);
  
  },[props.srednjeObrazovanje.tip, 
     props.srednjeObrazovanje.naziv,
     props.srednjeObrazovanje.smer,
     props.srednjeObrazovanje.grad,
     props.srednjeObrazovanje.drzava,
     props.srednjeObrazovanje.godinaZavrsetka,
     props.srednjeObrazovanje.id
    ])


    return ( 
      <Modal
        open={props.open}
        onClose={handleCloseAction}
        aria-labelledby="modal-personal-data-title"
        aria-describedby="modal-PP-description"
        sx={{zIndex: '1800'}}
      >
        <Paper variant="outlined" sx={styles.modalStyle}>

        <div className="flex justify-between max-h-[500px] px-4 py-2">

          <div className="flex px-4 py-2 text-blue-500 sm:text-xs">
            <p className="text-s sm:text-lg font-semibold">Izmenite podatke srednjeg obrazovanja</p>
          </div>

          <div className="flex">
                <IconButton
                    onClick = { handleCloseAction }
                    /* sx={styles.iconButtonStyle} */
                >
                  <CloseIcon />
                </IconButton>
          </div>
        </div>

        <Divider />

          <Box sx={styles.contentWraper}>

            {/* <Typography sx={{marginBottom: '0.5%', color: '#1976D2'}} variant="h7" component="h3"> Prebivalište </Typography> */}

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Tip obrazovne ustanove: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={tip}
                onChange={(e) => setTip(e.target.value)} 
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Naziv obrazovne ustanove: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={naziv}
                onChange={(e) => setNaziv(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Smer: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={smer}
                onChange={(e) => setSmer(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Grad: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={grad}
                onChange={(e) => setGrad(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Država: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={drzava}
                onChange={(e) => setDrzava(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> Godina završetka: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                type={'number'}
                defaultValue={godinaZavrsetka}
                onChange={(e) => setGodinaZavrsetka(e.target.value)}
                size="small"
              />
            </Box>

          </Box>
          
          <Divider />

          <Box sx={styles.actionButtonsBox}>
            
            <Box sx={styles.actionButtonsBoxSmall}>
              
              <IconButton
                    /* onClick = {handleOpenAddModal} */
                    /* sx={styles.iconButtonStyle} */
                >
                  <DeleteIcon sx={{ transform: 'scale(1.2)' }} />
                </IconButton>
              
              <Button onClick={handleSubmit} variant="contained">Sačuvaj</Button>
            </Box>
            
          </Box>
        
          <SuccessfulEditModal 
            open={openModalNtf}
            handleClose={handleCloseNtf}
          />

        </Paper>
      </Modal>

     );
}
 
export default HighschoolEducationModal;