import * as React from 'react';
import {
  Box,
  Typography,
  Modal,
  Paper,
  Divider,
  TextField,
  Button,
  Slider,
  IconButton
} from '@mui/material';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';

const styles = {
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24
  },
  contentWraper: {
    overflow: 'auto',
    display: "flex",
    flexDirection: 'column',
    height: '285px',
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  labelAndFieldBox: {
    marginBottom: '8%',
    marginTop: '1.5%',
  },
  actionButtonsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  },
  actionButtonsBoxSmall: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  actionButtonsBoxSmall2: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    marginTop: '10px'
  },
  buttonStyle: {
    marginLeft: '2%',
    width: '100%'
  },
  buttonStyle1: {
    height: '2rem',
    fontSize: '12px'
  }
};

const levels = [
  {
    value: 0,
    label: 'Početni',
  },
  {
    value: 25,
    label: 'Srednji',
  },
  {
    value: 50,
    label: 'Viši',
  },
  {
    value: 100,
    label: 'Napredni',
  },
];


const LanguageModal = (props) => {

  const [jezik, setJezik] = useState(props.language.jezik);
  const [nivoCitanja, setNivoCitanja] = useState(props.language.nivoCitanja);
  const [nivoGovora, setNivoGovora] = useState(props.language.nivoGovora);
  const [nivoPisanja, setNivoPisanja] = useState(props.language.nivoPisanja);
  const [nivoRazumevanja, setNivoRazumevanja] = useState(props.language.nivoRazumevanja);
  const [id, setId] = useState(props.language.id);

  const [openModalNtf, setOpenModalNtf] = useState(false);
  const handleOpenNtf = () => setOpenModalNtf(true);
  const handleCloseNtf = () => {
    setOpenModalNtf(false);
    props.handleClose();
  }

  const parseSliderValueToText = (value) => {
    switch (value) {
      case 0: return "Početni";
      case 25: return "Srednji";
      case 50: return "Viši";
      case 100: return "Napredni";
    }
  }

  const parseSliderValueToNumber = (value) => {
    switch (value) {
      case "Početni": return 0;
      case "Srednji": return 25;
      case "Viši": return 50;
      case "Napredni": return 100;
    }
  }

  const handleSubmit = () => {
    let userID = props.userID.toString();
    let newData = {
      payload: {
        nivoCitanja: nivoCitanja,
        nivoPisanja: nivoPisanja,
        nivoGovora: nivoGovora,
        nivoRazumevanja: nivoRazumevanja,
        jezik: jezik,
        fieldID: id,
        id: id,
        sertifikat: "",
        userID: userID
      },
      field: 'poznavanjeJezika'
    }
    props.handleChange(newData);
    handleOpenNtf();
  }

  const handleCloseAction = () => {
    props.handleClose();
  }

  useEffect(() => {
    setJezik(props.language.jezik);
    setId(props.language.id);
    setNivoCitanja(props.language.nivoCitanja);
    setNivoGovora(props.language.nivoGovora);
    setNivoPisanja(props.language.nivoPisanja);
    setNivoRazumevanja(props.language.nivoRazumevanja);
  }, [
    props.language.jezik,
    props.language.id,
    props.language.nivoCitanja,
    props.language.nivoGovora,
    props.language.nivoRazumevanja,
    props.language.nivoPisanja,
  ])

  return (
    <Modal
      open={props.open}
      onClose={handleCloseAction}
      aria-labelledby="modal-personal-data-title"
      sx={{ zIndex: '1800' }}
    >
      <Paper variant="outlined" sx={styles.modalStyle}>
        <div className="flex justify-between max-h-[500px] px-4 py-2">

          <div className="flex px-4 py-2 text-blue-500 sm:text-xs">
            <p className="text-s sm:text-lg font-semibold">Izmenite podatke o jezicima</p>
          </div>

          <div className="flex">
            <IconButton
              onClick={handleCloseAction}
            /* sx={styles.iconButtonStyle} */
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <Divider />

        <Box sx={styles.contentWraper}>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '1%', fontSize: '15px' }}> Jezik: </Typography>
            <TextField
              required
              id="outlined-required"
              fullWidth
              defaultValue={jezik}
              onChange={(e) => setJezik(e.target.value)}
              size="small"
            />
          </Box>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '1%', fontSize: '15px' }}> Nivo čitanja: </Typography>
            <Slider
              aria-label="Restricted values"
              defaultValue={parseSliderValueToNumber(nivoCitanja)}
              step={null}
              marks={levels}
              onChange={(e) => {
                let parsedValue = parseSliderValueToText(e.target.value);
                setNivoCitanja(parsedValue)
              }}
              valueLabelDisplay='off'
            />
          </Box>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '1%', fontSize: '15px' }}> Nivo pisanja: </Typography>
            <Slider
              aria-label="Restricted values"
              defaultValue={parseSliderValueToNumber(nivoPisanja)}
              step={null}
              marks={levels}
              onChange={(e) => {
                let parsedValue = parseSliderValueToText(e.target.value);
                setNivoPisanja(parsedValue)
              }}
              valueLabelDisplay='off'
            />
          </Box>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '1%', fontSize: '15px' }}> Nivo razumevanja: </Typography>
            <Slider
              aria-label="Restricted values"
              defaultValue={parseSliderValueToNumber(nivoRazumevanja)}
              step={null}
              marks={levels}
              onChange={(e) => {
                let parsedValue = parseSliderValueToText(e.target.value);
                setNivoRazumevanja(parsedValue)
              }}
              valueLabelDisplay='off'
            />
          </Box>

          <Box sx={styles.labelAndFieldBox}>
            <Typography sx={{ marginBottom: '1%', fontSize: '15px' }}> Nivo govora: </Typography>
            <Slider
              aria-label="Restricted values"
              defaultValue={parseSliderValueToNumber(nivoGovora)}
              step={null}
              marks={levels}
              onChange={(e) => {
                let parsedValue = parseSliderValueToText(e.target.value);
                setNivoGovora(parsedValue)
              }}
              valueLabelDisplay='off'
            />
          </Box>
        </Box>

        <Divider />

        <Box sx={styles.actionButtonsBox}>
          <Box sx={styles.actionButtonsBoxSmall}>
            <IconButton
            /* onClick = {handleOpenAddModal} */
            /* sx={styles.iconButtonStyle} */
            >
              <DeleteIcon sx={{ transform: 'scale(1.2)' }} />
            </IconButton>
            <Button onClick={handleSubmit} variant="contained">Sačuvaj</Button>
          </Box>
        </Box>

        <SuccessfulEditModal
          open={openModalNtf}
          handleClose={handleCloseNtf}
        />
      </Paper>
    </Modal>
  );
}

export default LanguageModal;