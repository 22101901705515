import * as React from 'react';
import { Box, Typography, Modal, Paper, Divider, TextField, Button, Link} from '@mui/material';
import history from "../../common/history";

const styles = {
    modalStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      boxShadow: 24,
    },
    contentWraper: {
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100px',
      paddingTop: '2%',
      paddingBottom: '2%',
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    labelAndFieldBox: {
      marginBottom: '2%'
    },
    labelAndFieldBoxLAST: {
      display: "flex",
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row'
    },
    actionButtonsBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      p: 2
    }
};

const SuccessfulEditModal = (props) => { 
  
    const handleCloseAction = () => {
      props.handleClose();
    }

    return ( 
        <Modal
          open={props.open}
          onClose={handleCloseAction}
          aria-labelledby="modal-personal-data-title"
          sx={{zIndex: '1800'}}
        >
            <Paper variant="outlined" sx={styles.modalStyle}>
                
              
                <div className="flex flex-col border-l-4 bg-green-100 border-green-500 text-green-700 p-4 rounded cursor-default" role="alert">
                  <p className="font-bold">PAŽNJA!</p>
                  <p>Uspešno ste uneli izmene.</p>
                </div>

            </Paper>
        </Modal>
    );
}
         
export default SuccessfulEditModal;