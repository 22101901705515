import React from 'react';
import './Footer.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import history from '../../common/history';
import { Typography, Icon, Box, IconButton } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import LogoBestWhite from "../../assets/BESTNis-beli-cropped.png";
import LogoJF23White from "../../assets/JF23-beli-cropped.png";
import CloseIcon from '@mui/icons-material/Close';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ButtonBase } from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';
//import Icon from '@material-ui/core/Icon';

const styles = {
  flex: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    //position: 'sticky'
  },
  topFooter: {
    top: 'auto',
    backgroundColor: "#1b75bc",
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  topFooterBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  topFooterBox1: {
    flexGrow: 1,
    textAlign: 'left',
    padding: 5,
    margin: 10,
  },
  topFooterBox2: {
    flexGrow: 1,
    textAlign: 'left',
    padding: 5,
    margin: 10,
  },
  topFooterBox3: {
    flexGrow: 1,
    textAlign: 'left',
    padding: 5,
    margin: 10
  },
  topFooterBox4: {
    flexGrow: 2,
    textAlign: 'right',
    padding: 5,
    marginTop: 5,
    marginRight: 15
  },
  bottomFooter: {
    top: 'auto',
    backgroundColor: "#1b75bc",
    flexGrow: 1,
    //position: 'sticky'
  },
  bottomFooterBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  bottomFooterBoxLeft: {
    marginLeft: 30
  },
  bottomFooterBoxCenter: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    flexGrow: 1
  },
  bottomFooterBoxRight: {
    marginTop: 5,
    marginRight: 15
  },
  marginLeft: {
    marginLeft: 15
  },
  logoStyle: {
    maxWidth: 250,
    maxHeight: 125,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logoStyleDiv1: {
    flexWrap: 'wrap',
    display: 'flex',
  },
  logoStyleDiv2: {
    flexWrap: 'wrap',
    display: 'flex',
  },
  socialIconsBest: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    paddingRight: '4%',
  },
  socialIconsJF: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    paddingLeft: '4%',
  },
  IconStyle: {
    color: 'white',
    fontSize: 32,
    '&:hover': {
      color: '#8a9aa6',
      cursor: 'pointer',
    },
  },
  CopyrightfStyle: {
    color: 'white',
    //fontSize: '14px',
    paddingTop: '5px'
  },
  CopyrightfStyleLaptopIcon: {
    color: 'white',
    //fontSize: '14px',
    paddingTop: '5px',
    // '&:hover': {
    //   cursor: 'pointer',
    // },
  },
  logoStyleImgDiv1: {
    maxWidth: 250,
    maxHeight: 125,
  },
  logoStyleImgDiv2: {
    maxWidth: 250,
    maxHeight: 125,
  },
  logoStyle2: {
    maxWidth: 190,
    maxHeight: 105,
    marginTop: '33px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  flaxWrapDiv: {
    display: 'flex',
    flexGrow: 0.25,
    justifyContent: 'center',
    padding: 5,
    margin: 10
  },
  contactDivContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '32px',
  },
  contactDivContentContainerJF: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '32px',
  },
  contactDivContentContainerBase: {
    display: 'flex',
    flexDirection: 'row',
  },
  typographyLinkStyle: {
    color: 'white',
    paddingTop: '5px'
  },
  emptyClass: {
  }
};

const Footer = () => {
  //const classes = useStyles();

  return (
    <footer className='bg-gray-900 text-white'>
      <div className='md:flex md:justify-around md:items-center sm:px-12 px-4 bg-[#686868]'>
        <a className="hidden sm:flex" href="https://bestnis.rs/" target="_blank" rel="noreferrer">
          <img className="max-w-[150px] w-full mx-auto" src={LogoBestWhite} alt=""></img>
        </a>
        <div className="flex justify-around mx-10">
          <div className="text-white bg-gradient-to-r from-blue-500 via-blue-600
                to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300
                dark:focus:ring-blue-800 rounded-full px-2 mx-1 text-center">
            <IconButton type="button">
              <a href="https://www.linkedin.com/company/jobfairnis/" target="_blank" rel="noreferrer">
                <LinkedInIcon style={{ color: 'white' }} />
              </a>
            </IconButton>
          </div>
          <div className="text-white bg-gradient-to-r from-blue-500 via-blue-600
                to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300
                dark:focus:ring-blue-800 rounded-full px-2 mx-1 text-center">
            <IconButton type="button" >
              <a href="https://www.facebook.com/jobfairnis/?locale=sr_RS" target="_blank" rel="noreferrer">
                <FacebookIcon style={{ color: 'white' }} />
              </a>
            </IconButton>
          </div>
          <div className="text-white bg-gradient-to-r from-blue-500 via-blue-600
                to-blue-700 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-300
                dark:focus:ring-blue-800 rounded-full px-2 mx-1 text-center">
            <IconButton type="button" >
              <a href="https://www.instagram.com/job_fair_nis/?hl=en" target="_blank" rel="noreferrer">
                <InstagramIcon style={{ color: 'white' }} />
              </a>
            </IconButton>
          </div>
        </div>
        <a className="hidden sm:flex" href="https://jobfairnis.rs/" target="_blank" rel="noreferrer">
          <img className="max-w-[150px] w-full mx-auto" src={LogoJF23White} alt=""></img>
        </a>
      </div>
      <div className='flex justify-center items-center sm:px-12 px-4 py-1 bg-[#b1b1b1]'>
        <p className="text-xs text-[#686868]"> BEST Nis - CV App • Powered with ❤ by IT team</p>
      </div>
    </footer>
  );
}

export default Footer;