import React from "react";
import {
    Paper,
    Typography,
    Box,
    Tooltip,
    IconButton,
    Divider
  } from "@mui/material";
  import EditIcon from '@mui/icons-material/Edit';
  import AddIcon from '@mui/icons-material/Add';
  import { useState } from "react";
import LanguageModal from "../LicniPodaciModals/LanguageModal";

import Avatar from '@mui/material/Avatar';
import TranslateIcon from '@mui/icons-material/Translate';
import LanguageModalAdd from "../LicniPodaciModalsAdd/LanguageModalAdd";

const styles = {
  paperStyle: {
    display: 'flex',
    flexDirection: 'column',
    //maxWidth: '1100px',
    maxWidth: '64rem',/* 1024px */
    width: '85%',
    flexWrap: 'wrap',
    padding: '24px',
    marginTop: '1.5%',
    marginBottom: '1.5%',
    borderRadius: '10px'
  },
  contentStyle0: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1%',
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '1%',
  },
  dataStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    flexGrow: 13
  },
  buttonsStyle : {
    display: 'flex',
    justifyContent: 'right',
    flexGrow: 1
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '3%',
  },
  headingStyle : {
    textAlign: 'start',
    marginBottom: '1%',
    color: 'primary.main',
    fontSize: '20px',
  },
  levelStyles: {
    textAlign: 'start',
    color: '#9592AE',
    fontSize: '13px'
  },
  dateStyles1: {
    textAlign: 'start',
  }
};

const LanguageCard = (props) => {

  const [openAddModal, setOpenAddModal] = useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const [openUpdateModal, setOpenUpdateModal] = useState(-1);
  const handleOpenUpdateModal = (itemID) => setOpenUpdateModal(itemID);
  const handleCloseUpdateModal = () => setOpenUpdateModal(-1);

    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>
          
          <Box sx={styles.contentStyle0}>
            <Typography sx={styles.headingStyle}> Strani jezik </Typography>
          
            <Box sx={styles.buttonsStyle}>
              {!props.preview &&
                <Box>
                  <Tooltip title="Dodaj">
                  <IconButton
                    onClick = {handleOpenAddModal}
                    sx={styles.iconButtonStyle}
                  >
                  <AddIcon />
                  </IconButton>
                  </Tooltip>
                  <LanguageModalAdd
                      handleAdd={props.handleAdd}
                      userID={props.userID}
                      open={openAddModal}
                      handleClose={handleCloseAddModal}
                    />
                </Box>
              }
            </Box>
          </Box>
          
          <Divider />
          {props.poznavanjeJezika && props.poznavanjeJezika.map((item, index) => (
          <Box key={index} sx={styles.contentStyle}>

            <Box className="hidden sm:flex items-center mr-[3%]"  /* sx={styles.avatarWrapper} */ >
              <Avatar sx={{ bgcolor: '#1B75BC', width: 50, height: 50 }} variant="rounded">
              <TranslateIcon />
              </Avatar>    
            </Box>

            <Box sx={styles.dataStyle}>
              
                    <Typography sx={{textAlign: 'start', fontWeight: '600', fontSize: 14}}>
                      {item.jezik }
                    </Typography>
                    <Typography variant="subtitle2" sx={styles.levelStyles}>
                      {`Čitanje: ${item.nivoCitanja} nivo`}
                    </Typography>
                    <Typography variant="subtitle2" sx={styles.levelStyles}>
                      {`Pisanje: ${item.nivoPisanja} nivo`}
                    </Typography>
                    <Typography variant="subtitle2" sx={styles.levelStyles}>
                      {`Razumevanje: ${item.nivoRazumevanja} nivo`}
                    </Typography>
                    <Typography variant="subtitle2" sx={styles.levelStyles}>
                      {`Govor: ${item.nivoGovora} nivo`}
                    </Typography>
            </Box>
         
            <Box sx={styles.buttonsStyle}>
            {!props.preview &&
              <Box>
                <Tooltip title="Izmeni">
                  <IconButton
                    onClick={() => handleOpenUpdateModal(index)}
                    sx={styles.iconButtonStyle}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            </Box>
          
            <LanguageModal 
            handleDelete={props.handleDelete}
            handleChange={props.handleChange}
            userID={props.userID}
            language={item}
            open={openUpdateModal === index ? true : false}
            handleClose={handleCloseUpdateModal}
          />
          </Box>

          ))}
        </Paper>
     );
}
 
export default LanguageCard;