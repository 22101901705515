import React from 'react';
import {  Button, Typography, Box, AccordionSummary, AccordionDetails, Accordion } from '@mui/material';
//import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
  heading: {
    textAlign: 'center',
    justifyContent: 'center',
    color: '#1b75bc',
  },
  faqDiv: {
    width: 'auto',
    padding: '3%',
    textAlign: 'center',
    justifyContent: 'center',
  },
  acordion: {
    '&:hover': { backgroundColor: '#F2F2F2' }
  },
  accordionDetailsStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    float: 'left'
  },
  typography: {
    align: 'left',
    textAlign: 'left'
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: '10px',
  },
  button: {
    marginBottom: '0px',
    color: '#1b75bc',
    borderColor: '#1b75bc',
    '&:hover': {
      color: 'white',
      borderColor: 'white',
      backgroundColor: '#1b75bc',
    },
  }
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const QACard = (props) => {
  //const classes = useStyles();
  return (
    <Accordion sx={styles.acordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" sx={styles.heading}>Q{props.index}: {props.QAItem.question}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetailsStyle}>
        <Typography sx={styles.typography}>A{props.index}: {props.QAItem.anwser}</Typography>
        {props.QAItem.isButton === 1 ?
          <Box sx={styles.buttonDiv}>
            <Button
              sx={styles.button}
              variant={"outlined"}
              color="primary"
              onClick={() => openInNewTab(props.QAItem.linkOnButton)}
            >SAZNAJ VIŠE
            </Button>
          </Box>
          : (<></>)}
      </AccordionDetails>
    </Accordion>
  );
}

export default QACard;