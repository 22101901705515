import * as React from 'react';
import {
  Box,
  Typography,
  Modal,
  Paper,
  Divider,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import { useState, useEffect } from 'react';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import SuccessfulEditModal from '../../genericModals/SuccessfulEditModal';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

/* import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers'; */

const styles = {
    modalStyle: {
      position: 'absolute',
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      boxShadow: 24
    },
    contentWraper: {
      overflow: 'auto',
      display: "flex",
      flexDirection: 'column',
      height: '285px',
      paddingTop: '2%',
      paddingBottom: '2%',
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    labelAndFieldBox: {
      marginBottom: '2%'
    },
    labelAndFieldBoxLAST: {
      display: "flex",
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row'
    },
    actionButtonsBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: 2
    },
    actionButtonsBoxSmall: {
      display: 'flex',
      width: '100%',
      justifyContent: 'end',
      flexDirection: 'row'
    },
    actionButtonsBoxSmall2: {
      display: 'flex',
      width: '100%',
      justifyContent: 'start',
      marginTop: '10px'
    },
    buttonStyle: {
      marginLeft: '2%',
      width: '100%'
    },
    buttonStyle1: {
      height: '2rem',
      fontSize: '12px'
    }
};


const PersonalDataModal = (props) => {
  const [name, setName] = useState(props.licniPodaci.ime);
  const [lastName, setLastName] = useState(props.licniPodaci.prezime);
  const [dateUpdated, setDateUpdated] = useState(new Date(props.licniPodaci.datumAzuriranja));
  const [dateOfBirth, setDateOfBirth] = useState(new Date(props.licniPodaci.datumRodjenja));
  const [phoneNum, setPhoneNum] = useState(props.kontakt.telefon);
  const [linkedIn, setLinkedIn] = useState(props.kontakt.linkedIn);
  const [email, setEmail] = useState(props.email);
  /* const [Pfp, setPfp] = useState(props.Pfp); */


  const [openModalNtf, setOpenModalNtf] = useState(false);
  const handleOpenNtf = () => setOpenModalNtf(true);
  const handleCloseNtf = () => 
    {
      setOpenModalNtf(false);
      props.handleClose();
    }

  const handleChangeDate = (newValue) => {
    setDateOfBirth(newValue.toDate());
  };

  const handleSubmit = () => {
    let userID = props.userID.toString();
    let newData = {
     userInfo: {
      payload: {
        ime: name,
        prezime: lastName,
        datumRodjenja: props.licniPodaci.datumRodjenja,
        datumAzuriranja: props.licniPodaci.datumAzuriranja,
        imeRoditelja: props.licniPodaci.imeRoditelja,
        profilnaSlika: props.licniPodaci.profilnaSlika,
        cv: props.licniPodaci.cv,
        userID: userID
      },
      field: 'licniPodaci'
    },
    contact: {
      payload: {
        telefon: phoneNum,
        linkedIn: linkedIn,
        userID: userID
      },
      field: 'kontakt'
      }
    }
    props.handleChange(newData);
    handleOpenNtf();
  }

  const handleCloseAction = () => {
    props.handleClose();
  }

  useEffect(() => {
    setName(props.licniPodaci.ime);
    setLastName(props.licniPodaci.prezime);
    setLinkedIn(props.kontakt.linkedIn);
    setPhoneNum(props.kontakt.telefon);
  },[props.kontakt.linkedIn, props.kontakt.telefon, props.licniPodaci.prezime, props.licniPodaci.ime])


    return ( 
      <Modal
        open={props.open}
        onClose={handleCloseAction}
        aria-labelledby="modal-personal-data-title"
        aria-describedby="modal-PP-description"
        sx={{zIndex: '1800'}}
      >
      <Paper variant="outlined" sx={styles.modalStyle}>
        <div className="flex justify-between max-h-[500px] px-4 py-2">

                <div className="flex px-4 py-2 text-blue-500 sm:text-xs">
                    <p className="text-s sm:text-lg font-semibold">Izmenite lične podatke</p>
                </div>

                <div className="flex">
                    <IconButton
                        onClick = { handleCloseAction }
                        /* sx={styles.iconButtonStyle} */
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>

            <Divider />    

          <Box sx={styles.contentWraper}>
            
            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}> *Ime: </Typography>
              <TextField
                required
                id="outlined-required"
                fullWidth
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                size="small"
              />
            </Box>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}>*Prezime:</Typography>
              <TextField
                required
                fullWidth
                id="outlined-required"
                defaultValue={lastName}
                onChange={(e) => setLastName(e.target.value)}
                size="small"
              />
            </Box>

            <LocalizationProvider dateAdapter={DateAdapter}>
                <Box sx={styles.labelAndFieldBox}>
                  <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}>*Datum rođenja:</Typography>
                  {/* <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="de"
                    localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
                  > */}
                    <DatePicker
                    required
                    maxDate={moment().subtract(18, 'years')}
                    value={dateOfBirth}
                    onChange={handleChangeDate}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                  />

                  {/* </LocalizationProvider> */}
                </Box>
            </LocalizationProvider>

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}>*Broj telefona:</Typography>
              <TextField
                required
                fullWidth
                id="outlined-required"
                defaultValue={phoneNum}
                onChange={(e) => setPhoneNum(e.target.value)}
                size="small"
              />
            </Box>
            {/* <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}>*Email:</Typography>
              <TextField
                required
                fullWidth
                id="outlined-required"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
              />
            </Box> */}

            <Box sx={styles.labelAndFieldBox}>
              <Typography sx={{marginBottom: '0.5%', fontSize: '14px'}}>LinkedIn:</Typography>
              <TextField
                fullWidth
                id="outlined-required"
                defaultValue={linkedIn}
                onChange={(e) => setLinkedIn(e.target.value)}
                size="small"
              />
            </Box>

            {/* <Box sx={styles.labelAndFieldBoxLAST}>
              <Typography sx={{marginBottom: '0.5%'}}>Promenite sliku profila:</Typography>
              <Button
                sx={styles.buttonStyle1}
                variant="contained"
                component="label"
              >
                Upload File
                <input
                  type="file"
                  hidden
                  onChange={(e) => setPfp(e.target.value)}
                />
              </Button>
             
            </Box> */}

          </Box>
          
          <Divider />

          <Box sx={styles.actionButtonsBox}>
            
                <Box sx={styles.actionButtonsBoxSmall}>
                    
                    {/* <IconButton>
                        <DeleteIcon sx={{ transform: 'scale(1.2)' }} />
                    </IconButton> */}
                    
                    <Button onClick={handleSubmit} variant="contained">Sačuvaj</Button>
                </Box>
                    
                </Box>

          <SuccessfulEditModal 
            open={openModalNtf}
            handleClose={handleCloseNtf}
          />
        </Paper>
      </Modal>
    );
}
 
export default PersonalDataModal;