import axios from 'axios'
import cookie from 'js-cookie'

class AxiosInstance {

  constructor(config) {
    //console.log(process.env);
    this.instance = axios.create({baseURL: process.env.REACT_APP_MAIN_URL});

    this.instance.interceptors.response.use(response => {

      const {data} = response;

      if (data && data.status === 409) {
        
      }

      return response
    })

    this.instance.interceptors.request.use(config => {

      // if (config.method !== "GET") {
      //   const token = sessionStorage.getItem('token');
      //   if (token === 'guest') {
      //     throw 'You are logged in as a guest, to continue log in with an existing account or create a new one'
      //   }
      // }

      //console.log("config");
      //console.log(config);

      if (config.url !== '/users/auth') {
        config.headers.Authorization = `Bearer ${cookie.get('token')}`
      }

      return config
    })

    // this.instance.interceptors.request.use(request => {
      
    // })

  }
}

export default new AxiosInstance({}).instance;