  import React, { useState, useEffect } from "react";
  import { connect } from "react-redux";
  import { sendVerifyAccount } from './AccountVerificationActions';
  
  const AccountVerification = (props) => {
  
    const [title, setTitle] = useState("CV App");
  
    useEffect(() => {
      setTitle(`Validacija naloga | CV App`);
      props.sendVerifyAccount(window.location.pathname.split("/").pop());
    }, []);
  
    return (
      <div>
        Uspesno verifikovano
      </div>
    );
  };
  
  const mapStateToProps = (state) => {
    return {
    }; 
  };
  
  export default connect(mapStateToProps, { sendVerifyAccount })(AccountVerification);
